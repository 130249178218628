import {Col, Row} from "react-bootstrap";
import React from "react";

export const TableRow = props => {
    const { data, schema = [], onSelect } = props;
    return (
        <Row
            tabIndex="1"
            onClick={() => onSelect(data)}
            className="m-0 w-100 table-row"
        >
            { schema.map((item, index)=>(
                <Col key={index} className={`${item.classes} p-3`}>
                    {item?.formatter? item.formatter(data) : data[item.name] }
                </Col>
            ))}
        </Row>
    )
}