/* eslint-disable no-restricted-globals */
import React, { useEffect, useReducer, useRef } from "react";
import { Endpoint } from "../api/base";
import { Row, Col, Table, Button, Alert, Spinner, Badge } from "react-bootstrap";
import { Formik, Form, FieldArray, Field } from "formik";
import { useParams } from "react-router-dom";
import { useOptionsState } from '../helpers/optionsContext'
import { formatToLocal } from "../helpers/dateHelpers";
import NotesList from '../components/NotesList'
import "./CheckinForm.css"
import Swal from "sweetalert2";
import {ConfirmModal} from "../components/WaitList/ConfirmModal";

const initialState = {user: [], loading: false, noteEdit: '', postNote: false}
function reducer (state, action)  {
    switch(action.type) {
        case 'setUser':
            return {...state, user: action.payload}
        case 'setLoading':
            return {...state, loading: action.payload}
        case 'setNoteEdit':
            return {...state, noteEdit: action.payload}
        case 'setPostNote':
            return {...state, postNote: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export const GrayBin = (props) => {
    const { CheckInType, InventoryItemType } = useOptionsState()
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    const {xs = 12, sm = 12, md = 12, lg = 12, xl = 12, isCheckinForm = false} = props;
    const params = useParams()
    const notesListRef = useRef()
    const noteDescription = useRef()

    useEffect(() => {
        if (!props.clientId){
            Endpoint.Clients.get(params.id).then(response => {
                dispatch({ type: 'setUser', payload: response.data })
            }).catch(err => {
                console.error(err)
                //Failed to fetch user profile
                Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
            })
        }
      }, [params.id, props.clientId]);

      const formatGrayInventory = (values) => {
        console.log(values.inventory)
        if(!values.inventory || values.inventory.length === 0) {
          return false
        }
        const inventoryArr = []
        values.inventory.forEach( invent => {
          if(!invent.isNew) {
            return 
          }
          let obj = {
            "InventoryItemTypeId": parseInt(invent.InventoryItemTypeId),
            "Description": invent.description,
            "ListAction": 1
          }
          inventoryArr.push(obj)
        })
        return inventoryArr
      }
    

      const handleSubmit = async (values) => {
        const clientId = props.clientId ? props.clientId : state.user.id
        console.log(values)


          dispatch({ type: 'setLoading', payload: true })
        try {
          // Inventory Checkin
          if(formatGrayInventory(values)) {
              const inventoryPayload = {
                "ClientId": clientId,
                "IsEmptyBin": values.isEmpty,
                "grayInventory": values.inventory
            }
            // alert(JSON.stringify(inventoryPayload)); return
            const inventoryResp = await Endpoint.Inventory.update(inventoryPayload)
              dispatch({ type: 'setLoading', payload: false })

            // Check in
            if(inventoryResp && !props.clientId) {
                dispatch({ type: 'setLoading', payload: true })
                const checkinPayload = {
                  "ClientId": clientId,
                  "CheckInTypeId": parseInt(values.checkinType)
                }
                // eslint-disable-next-line
                await Endpoint.Clients.checkin(checkinPayload)
            }
            } else {
              dispatch({ type: 'setLoading', payload: false })
            }
            dispatch({ type: 'setLoading', payload: false })
            location.reload()
        } catch (err) {
            dispatch({ type: 'setLoading', payload: false })
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
        }

    }

      const handleNotes = async () => {
        const clientId = props.clientId ? props.clientId : state.user.id
        if(state.noteEdit !== undefined) {
          const notesPayload = [{
            "NoteTypeId": 4,
            "Value": state.noteEdit,
            "ListAction": 1
          }]
          // eslint-disable-next-line
          try {
            const notesResp = await Endpoint.Clients.Notes.new({ id: clientId, data: notesPayload })
            if(notesResp) {
                dispatch({ type: 'setNoteEdit', payload: undefined })
                await notesListRef.current.reloadNotes()
                if(noteDescription.current) noteDescription.current.value = ""
            Swal.fire(
              'Success!',
              'Message has been added',
              'success'
            ).then( async result => {
                location.reload()
            })
            }
          } catch (err) {
              if(err.response) {
                  console.log(err.response.data)
              }
          }
          
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please add a note/message',
          })
        }
      }
    return (props.clientId || state.user ? (
        <div>
            <Formik
                initialValues={{
                    inventory:  props?.inventory?.grayInventory,
                    isEmpty: false,
                    description: props.clientId ? props?.inventory?.notes : state.user?.inventory?.notes,
                    checkinType: Object.keys(CheckInType||{})[0],
                    showSubmitConfirm: false

                }}
                onSubmit={handleSubmit}
            >
                {({submitForm, values, handleChange, setFieldValue}) => (
                <Form>
                    <Row className="align-items-center flex-column py-3 m-0">

                        <ConfirmModal
                            loading={state.loading}
                            text="Are you sure you want to submit this Inventory List?"
                            submitText="Submit"
                            submitVariant="success"
                            isOpen={values.showSubmitConfirm}
                            handleClose={() => handleChange({ target: { name: "showSubmitConfirm", value: false } })}
                            onConfirm={() => {
                                handleChange({ target: { name: "showSubmitConfirm", value: false } });
                                submitForm();
                            }}
                        />

                        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="py-3 px-3 pb-4">
                            { isCheckinForm && <h3>{`${state.user?.firstName} ${state.user?.lastName}'s Bin`}</h3>}

                            { !isCheckinForm && (
                                <div className="form-check">
                                    <Field className="form-check-input" type="checkbox" name="isEmpty" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Designate as an Empty Bin</label>
                                </div>
                            )}

                        </Col>

                        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="px-0 pb-2">
                            { isCheckinForm && (
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Checkin Type</label>
                                        <Field
                                            className="form-control form-control-sm"
                                            as="select"
                                            size="sm"
                                            name={`checkinType`}
                                        >
                                        {Object.keys(CheckInType).map( typeKey =>
                                            <option key={typeKey} value={typeKey} label={CheckInType[typeKey]} />
                                        )}
                                    </Field>
                                </div>
                            )}
                        </Col>
                        <FieldArray name="inventory">
                            {(inventoryActions) => (
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                    <Row>
                                        {values.isEmpty ?
                                            <Alert variant="warning" className="w-100 mx-3 my-0" dismissible>
                                                <p className="my-0">Are you sure you want to <span className="font-weight-bold">Empty</span> the bin!</p>
                                            </Alert>
                                            :
                                            (
                                            <>
                                            <Table >
                                                <thead>
                                                    <tr>
                                                        {/* <th>#</th> */}
                                                        <th>Item Type</th>
                                                        <th>Item Quantity</th>
                                                        <th>Description</th>
                                                        {/*<th>Employee</th>*/}
                                                        <th>Date</th>
                                                        <th className="px-2 " >
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                <tbody>
                                                    {values?.inventory?.map((item, index) => {
                                                        return (
                                                        <tr key={index}>
                                                            {/* <td>{item.id}</td> */}
                                                            <td className="p-2 select">
                                                                <Field
                                                                    className="form-control form-control-sm"
                                                                    as="select"
                                                                    size="sm"
                                                                    name={`inventory.${index}.InventoryItemTypeId`}
                                                                    disabled={!item.isNew}
                                                                    defaultValue={item.inventoryItemTypeId}
                                                                >
                                                                    {Object.keys(InventoryItemType).map( inType =>
                                                                        <option key={inType} value={inType} label={InventoryItemType[inType]}/>
                                                                    )}
                                                                </Field>

                                                            </td>
                                                            <td className="p-2">
                                                                <Field
                                                                    className="form-control form-control-sm"
                                                                    type="number"
                                                                    name={`inventory.${index}.quantity`}
                                                                    disabled={!item.isNew}
                                                                    onChange={e => {
                                                                        e.preventDefault();
                                                                        const { value } = e.target;
                                                                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                                        if (regex.test(value.toString())) {
                                                                            setFieldValue(`inventory.${index}.quantity`, value);
                                                                            if(!item.isNew)
                                                                                setFieldValue(`inventory.${index}.ListAction`, 2);
                                                                        }
                                                                    }}
                                                                    placeholder="Quantity"
                                                                />
                                                            </td>
                                                            <td className="p-2">
                                                                <Field
                                                                    className="form-control form-control-sm"
                                                                    type="text"
                                                                    name={`inventory.${index}.description`}
                                                                    disabled={!item.isNew}
                                                                    onChange={e => {
                                                                        const { value } = e.target;
                                                                        setFieldValue(`inventory.${index}.description`, value);
                                                                        if(!item.isNew)
                                                                            setFieldValue(`inventory.${index}.ListAction`, 2);
                                                                    }}
                                                                    placeholder="description"
                                                                />
                                                            </td>
                                                            {/*<td>{item?.employee || "-"}</td>*/}
                                                            <td>{formatToLocal(item.date)}</td>
                                                            <td className="action-column-items px-2 py-2 text-right" >
                                                                {item.isNew ?
                                                                    <Button
                                                                        onClick={() => {
                                                                            inventoryActions.remove(index)
                                                                        }}
                                                                        size="sm"
                                                                        variant="outline-danger btn-borderless text-danger w-100"
                                                                    >
                                                                            Remove
                                                                    </Button>
                                                                :
                                                                    item.isDeleted? <Badge variant="warning">Deleted</Badge>
                                                                :
                                                                    <Button
                                                                       onClick={() => {
                                                                           inventoryActions.push({
                                                                               checkInType: item.checkInType,
                                                                               InventoryItemTypeId: item.inventoryItemTypeId,
                                                                               quantity: item.quantity,
                                                                               description: item.description,
                                                                               id: item.id,
                                                                               isDeleted: "true",
                                                                               date: item.date,
                                                                               ListAction: 3
                                                                           })
                                                                           inventoryActions.remove(index)

                                                                       }}
                                                                       size="sm"
                                                                       variant="outline-danger btn-borderless text-danger w-100"
                                                                   >
                                                                       Remove
                                                                   </Button>
                                                               }
                                                            </td>
                                                        </tr>
                                                    )})}
                                                </tbody>

                                            </Table>
                                        </>
                                        ) }
                                    </Row>
                                </Col>
                            )}
                        </FieldArray>
                        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="px-0">
                            <Row className="justify-content-center m-0">
                                <Button
                                    className="px-5 check-in-form-submit-button"
                                    variant="success"
                                    disabled={state.loading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleChange({ target: { name: "showSubmitConfirm", value: true } });
                                    }}
                                >
                                    {state.loading ? <Spinner size="sm" animation="border" variant="primary" />: "Submit"}
                                </Button>
                            </Row>
                        </Col>
                        <NotesList title="Inventory Notes" ref={notesListRef} noteTypeId={4} clientId={props.clientId ? props.clientId : state.user.id} />
                        {state.postNote ? (
                            <>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="pt-5 pb-2 px-2 ">
                                    <div className="form-group">
                                        <Field
                                            innerRef={noteDescription}
                                            as="textarea"
                                            name="description"
                                            id="inventory-description"
                                            className="form-control"
                                            rows="3"
                                            value={state.noteEdit}
                                            onChange={e => {
                                                const { value } = e.target;
                                                setFieldValue(`description`, value)
                                                dispatch({ type: 'setNoteEdit', payload: value })
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <Row className="justify-content-center">
                                        <Button className="px-5" style={{ marginBottom: '2rem' }} variant="outline-success" onClick={() => handleNotes()}  disabled={values.description === undefined}>
                                            Submit Post
                                        </Button>
                                    </Row>
                                </Col>
                            </>
                        ) : (
                            <Col xs={4}>
                                <Row style={{ marginTop: '15px' }} className="justify-content-center">
                                    <Button className="px-5" style={{ marginBottom: '2rem' }} variant="outline-success" onClick={() => {
                                        dispatch({ type: 'setPostNote', payload: true })
                                    }}>
                                        Post a Note
                                    </Button>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Form>
                )}
            </Formik>

        </div>
        )
        :
        (
            <Row className="checkin-form-loading-container m-0 justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
            </Row>
        )
    )
};

export default GrayBin;
