import React, {useReducer} from "react";
import {Button, Table} from 'react-bootstrap';
import { formatToLocal } from "../../helpers/dateHelpers";
import {Endpoint} from "../../api/base";
import Swal from "sweetalert2";
import {ConfirmModal} from "../WaitList/ConfirmModal";
import Col from "react-bootstrap/Col";

const initialState = {checkInId: undefined, showDeleteConfirm: false, loading: false};
function reducer (state, action)  {
    switch(action.type) {
        case 'setCheckInId':
            return {...state, checkInId: action.payload}
        case 'setShowDeleteConfirm':
            return {...state, showDeleteConfirm: action.payload}
        case 'setLoading':
            return {...state, loading: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export const ClientCheckInHistory = ({ history, clientId, data, types }) => {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)

    const openDelete = (checkInId) => {
        dispatch({ type: 'setCheckInId', payload: checkInId });
        dispatch({ type: 'setShowDeleteConfirm', payload: true });
    }

    const handleDelete = () => {
        if(data.length === 1)
            handleDeleteProfile();
        else
            handleDeleteCheckin();
    }

    const handleDeleteProfile = () => {
        dispatch({ type: 'setLoading', payload: true });
        Endpoint.Clients.delete(clientId).then(() => {
            dispatch({ type: 'setShowDeleteConfirm', payload: false });
            dispatch({ type: 'setLoading', payload: false });
            history.push(`/app/clients`);
        }).catch(err => {
            //failed to delete!
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error');
            dispatch({ type: 'setLoading', payload: false });
        })
    }

    const handleDeleteCheckin = () => {
        dispatch({ type: 'setLoading', payload: true });
        Endpoint.Clients.CheckIn.delete({id: state.checkInId}).then(() => {
            dispatch({ type: 'setShowDeleteConfirm', payload: false });
            dispatch({ type: 'setLoading', payload: false });
            window.location.reload();
        }).catch(err => {
            //failed to delete!
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error');
            dispatch({ type: 'setLoading', payload: false });
        })
    }

    return (
        <>
            {data && data.length ?
                <>
                    <ConfirmModal
                        loading={state.loading}
                        text={
                            data.length === 1 ? "This profile will be deleted if you delete this checkin. Are you sure you want to proceed with this action?" :
                                "Are you sure you want to delete this Check In?"
                        }
                        isOpen={state.showDeleteConfirm} handleClose={() => dispatch({ type: 'setShowDeleteConfirm', payload: false })}
                        onConfirm={handleDelete}/>
                    <Table>
                        <thead>
                        <tr className="text-center">
                            <th className="border-top-0">Action</th>
                            <th className="border-top-0">Employee</th>
                            <th className="border-top-0">Check In Type</th>
                            <th className="border-top-0">Check In Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, index) => (
                            <tr className="text-center" key={item.id || index}>
                                <td><Button variant="outline-danger" onClick={() => openDelete(item.id)} size="sm">Delete</Button></td>
                                <td>{item.employee || "-"}</td>
                                <td>{item.checkInTypeId && types ? types[item.checkInTypeId] : "-"}</td>
                                <td>{formatToLocal(item.checkInTime+"Z")}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </>
                :
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="py-3 px-3 pb-4">
                    <p>Not enough data to display.</p>
                </Col>
            }
        </>
    );

}


export default ClientCheckInHistory;
