import React, { useEffect, useMemo, useReducer } from "react";
import { Endpoint } from "../api/base";
import { Col, Row } from "react-bootstrap";
import ClientInfoPopUp from "../components/ClientInfoPopUp";
import Loading from '../components/Loading'
import { formatToLocal } from '../helpers/dateHelpers'
import { Table, DATA_TYPES } from "../components/Table"

const schema = [
  {
    label: "Bin Number",
    name: "binNum",
    type: DATA_TYPES.STRING,
    formatter: (data) => <p className="text-left w-100 m-0">{data.binNum}</p>
  },{
    label: "Last Checkin",
    name: "lastCheckinDate",
    type: DATA_TYPES.DATE,
    formatter: (data) => {
      if(new Date(data.lastCheckinDate).getTime() !== 0){
        return <p className="text-left w-100 m-0">{formatToLocal(data.lastCheckinDate)}</p>;
      } else {
        return <p className="text-left w-100 m-0">N/A</p>;
      }
    },
  },{
    label: "Expiration Date",
    name: "expirationDate",
    type: DATA_TYPES.DATE,
    formatter: (data) => {
      if(new Date(data.expirationDate).getTime() !== 0){
        return <p className="text-left w-100 m-0">{formatToLocal(data.expirationDate)}</p>;
      } else {
        return <p className="text-left w-100 m-0">N/A</p>;
      }
    }
  },{
    label: "Status",
    name: "status",
    type: DATA_TYPES.OPTION,
    options: [
      { label: "Available", value: 0 },
      { label: "Occupied", value: 1 },
    ],
    formatter: (data) => data.status === 0 ? <span className="text-success">Available</span> : <span className="text-danger">Occupied</span>
  }
];


// const getCellLink = (value, row) =>{
//   const path = row.status === 1 ? `/app/clients/${row.clientId}` : `/app/addclient/${row.id}`;
//   return <Link to={path}>{value}</Link>
// }
const initialState = {binsData: [], loading: true, clientId: undefined, clientInfoPopUpShow: false}
function reducer (state, action)  {
  switch(action.type) {
    case 'setBinsData':
      return {...state, binsData: action.payload}
    case 'setLoading':
      return {...state, loading: action.payload}
    case 'setClientId':
      return {...state, clientId: action.payload}
    case 'setClientInfoPopUpShow':
      return {...state, clientInfoPopUpShow: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

function BinList() {
  const [state, dispatch] = useReducer(reducer, initialState, undefined)

  useEffect(() => {
    const fetchData = async () => {
      const response = await Endpoint.BinList.all();

      const binsData = response.data.map(item => ({
        ...item,
        expirationDate: item.expirationDate ? item.expirationDate+"Z" : new Date(0).toISOString(),
        lastCheckinDate: item.lastCheckinDate ? item.lastCheckinDate+"Z" : new Date(0).toISOString(),
      }))
      dispatch({ type: 'setBinsData', payload: binsData })
      dispatch({ type: 'setLoading', payload: false })
    };
    fetchData();
  }, []);

  const bins = useMemo(() => {
    const bins = state.binsData.map(item => {
      return {
        ...item,
        id: item.binId,
        binNum: item.binNumber,
        clientId: item.clientId,
        expirationDate: item.expirationDate,
        lastCheckinDate: item.lastCheckinDate,
        status: item.clientId? 1 : 0,
        isEmptyBin: item.isEmptyBin,
      }
    })
    return bins
  }, [state.binsData])


  return (
      <Row className="m-0 flex-nowrap w-100">
        { !state.loading && true &&
        <Col
            style={{minHeight: "calc(100vh - 70px)", flex: "1"}}
            className="m-0 pl-2 pr-2 pl-lg-0 pr-lg-3 py-4"
        >
          <Table
              onSelect={(data) =>{
                dispatch({ type: 'setClientId', payload: data?.clientId || undefined })
                dispatch({ type: 'setClientInfoPopUpShow', payload: true })
              }}
              list={bins}
              uniqeKeyName="binNum"
              schema={schema}
              pageSizeOptions={[
                {label: "10", value: 10},
                {label: "20", value: 20},
                {label: "30", value: 30},
                {label: "40", value: 40},
                {label: "250", value: 250},
                {label: "500", value: 500},
              ]}
          />
        </Col>
        }

        { state.loading && <Loading height="calc(100vh - 80px)" />}

        { !state.loading && state.clientId &&
        <ClientInfoPopUp
            status={state.clientInfoPopUpShow}
            onClose={() => dispatch({ type: 'setClientInfoPopUpShow', payload: false })}
            id={state.clientId}
        />
        }
      </Row>
  );
}

export default BinList;
