import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { login } from '../api/authProvider'
import { Button, Navbar, Row, Col, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import Swal from "sweetalert2";

const NavBar = props => (
  <Navbar className="w-100" variant="light" 
  style={{ marginBottom: '20px', padding: 0}}>
  <Row className="w-100" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Col style={{ paddingLeft: '24px' }}>
      <div className="contact w-75" style={{ borderBottom: '3px solid red' }}>
          <small>Contact Us</small>
            <h4 style={{ margin: 0 }}>+745 321 567</h4>
      </div>
    </Col>
    <Col xs={6} 
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}
    > <img src="/images/sc-logo.svg" className="w-25" alt="sc logo" /> </Col>
    <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 0 }}> 
    <Button variant="danger" size="lg" className="w-20" onClick={props.handleLogin}>LOGIN</Button>
    </Col>
  </Row>

</Navbar>
)

const LoginPage = (props) => {
  const handleLogin = async (e) => {
    const token = await login().catch(err => {
        //something went wrong, please try again
        Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
    })
    if(token){
      props.history.push("/")
    }
  }
  
  return (
    <Container>
          <NavBar handleLogin={handleLogin}/>
          <img src="/images/sc-logo.svg" className="w-100 h-75" style={{ borderRadius: '25px' }} alt="sc logo" />      
          <div className="icons"     
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}
          >
        
              <Nav.Link className="p-0" eventKey="link-1">
                <FontAwesomeIcon icon={faFacebook} />
              </Nav.Link>
              <Nav.Link className="p-0" eventKey="link-2">
                <FontAwesomeIcon icon={faTwitter} />
              </Nav.Link>
             <Nav.Link className="p-0" eventKey="link-3">
                <FontAwesomeIcon icon={faInstagram} />
              </Nav.Link>
          </div>
    </Container>
  );
}

export default withRouter(LoginPage)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  height: 100vh;
  background: #E2E6E6;
`;
