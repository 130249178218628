import React, { useEffect, useReducer } from "react";
import { SideBar, Main, TopBar } from "./components/Layout";
import NotFoundPage from "./pages/NotFoundPage";
import ClientPage from "./pages/ClientPage";
import AddClientPage from "./pages/AddClientPage";
import HomePage from "./pages/HomePage";
import BinList from "./pages/BinList";
import LoginPage from "./pages/LoginPage";
import ClientProfilePage from "./pages/ClientProfilePage";
import UploadPhotoPage from "./pages/UploadPhotoPage";
import { HashRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";

import { getAccessToken, handleRedirectPromise} from './api/authProvider'
import { setOptions, useOptionsDispatch } from './helpers/optionsContext'
import Loading from './components/Loading'
import CheckInForm from "./components/CheckInForm"
import WaitList from "./components/WaitList"
import { Endpoint } from "./api/base";

const initialState = {token: null, isLoading: true}
function reducer (state, action)  {
    switch(action.type) {
        case 'setToken':
            return {...state, token: action.payload}
        case 'setIsLoading':
            return {...state, isLoading: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

function App() {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    const optionDispach = useOptionsDispatch()

    useEffect(() => {
        const getToken = async () => {
            let tokenValue = await handleRedirectPromise().catch(e => { console.log(e) })

            if (!state.token){
                tokenValue = await getAccessToken().catch(e => { console.log(e) })
            }

            dispatch({ type: 'setToken', payload: tokenValue })
            dispatch({ type: 'setIsLoading', payload: false })
            if(tokenValue){
                Endpoint.AppConstants.get().then(res=>{
                    setOptions(optionDispach, res.data)
                })
                //TODO: getOptions
            }
        }

        getToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <React.Fragment>
            { state.isLoading ? <Loading />:
                <HashRouter>
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="/app" />} />
                        <PrivateRoute path="/app" component={MainApp} isAuthenticated={!!state.token} />
                        <PublicRoute path="/login" component={LoginPage} isAuthenticated={!!state.token} />
                        <Route component={NotFoundPage} />
                    </Switch>
                </HashRouter>
            }
        </React.Fragment>
    )
}

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    React.createElement(component, props)
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                from: props.location,
                            },
                        }}
                    />
                )
            }
        />
    );
}

const PublicRoute = ({ component, isAuthenticated,  ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                ) : (
                    React.createElement(component, props)
                )
            }
        />
    );
}

const MainApp = withRouter(({match}) => {
    return (
        <div className="row m-0 main-layout-container flex-column-reverse flex-lg-row" >
            <SideBar />
            <Main>
                <TopBar/>
                <Switch>
                    <Route path={`${match.url}/bins`} component={BinList} />
                    <Route path={`${match.url}/clients/:id/:tabKey?`} component={ClientProfilePage} />
                    <Route path={`${match.url}/clients`} component={ClientPage} />
                    <Route path={`${match.url}/add-client/:binid?`} component={AddClientPage} />
                    <Route path={`${match.url}/wait-list/intake/:waitlistId?`} component={AddClientPage} />
                    <Route path={`${match.url}/edit-client/:id`} component={AddClientPage} />
                    <Route path={`${match.url}/upload-photo/:clientId`} component={UploadPhotoPage} />
                    <Route path={`${match.url}/wait-list`} component={WaitList} />
                    <Route path={`${match.url}/check-in-form/:id`}>
                        <CheckInForm isCheckinForm isCheck xs={10} sm={9} md={9} lg={9} xl={9} />
                    </Route>
                    <Route path={`${match.url}/`} exact component={HomePage} />
                    <Route component={NotFoundPage} />
                </Switch>
            </Main>
        </div>
    );
})

export default App;
