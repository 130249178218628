import React, { useEffect, useReducer } from "react";
import {Col, Row, Button, Image, Spinner, Modal} from "react-bootstrap";
import { Endpoint } from '../../api/base'
import { useOptionsState } from '../../helpers/optionsContext'
import ClientCloseOut from '../ClientProfile/ClientCloseOut'
import { Link } from "react-router-dom";
import "./index.css";
import EnrolPopUp from "../ClientProfile/EnrolPopUp";

const Placeholder = ({ width = 3, className }) =>  <div className={`side-bar-placeholder ${className}`} style={{width: `${width * 20}px`}}/>

const initialState = {user: undefined, modalShow: false, profilePicture: undefined}
function reducer (state, action)  {
    switch(action.type) {
        case 'setUser':
            return {...state, user: action.payload}
        case 'setModalShow':
            return {...state, modalShow: action.payload}
        case 'setProfilePicture':
            return {...state, profilePicture: action.payload}
        case "resetStore":
            state = initialState
            return state;
        default:
            throw new Error("Something went wrong!")
    }
}

export const ClientInfoPopUp = props => {
    const { status, onClose, id } = props;

    const { ReferralInfo } = useOptionsState()
    const [state, dispatch] = useReducer(reducer, initialState, undefined)

    useEffect(() => {
        let isSubscribed = true;
        (async () => {
            const response = await Endpoint.Clients.get(id);
            console.clear()
            console.log(response.data)
            if (isSubscribed) {
                dispatch({ type: 'setUser', payload: response.data })
                Endpoint.Clients.getImage(id).then(res => {
                    if (isSubscribed) {
                        dispatch({ type: 'setProfilePicture', payload: URL.createObjectURL(res.data)})
                    }
                }).catch(e => {
                })
            }
        })()

        return () => {
            isSubscribed = false;
        }
    }, [id]);

    const onHide = () => {
        dispatch({ type: 'resetStore'});
        onClose();
    }


    console.log(state.user)
    return (
        <Modal show={status} size="lg" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Profile Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="m-0 pt-0 pb-0 h-100 w-100">
                    <Col className="w-100 pl-0  p-3 row m-0">
                        <div className="w-100">
                            <div className="row m-0 mb-1 w-100 justify-content-between align-items-center">

                                { !state.user && <Spinner size="sm" animation="border" variant="success" />}
                            </div>

                            <div className="mb-3 w-100">
                                <Row xs="auto" className="m-0 p-0 pr-3 flex-nowrap">
                                    <Image
                                        className="client-side-bar-avatar mr-2"
                                        src={state.profilePicture} alt="Profile"
                                        roundedCircle
                                    />

                                    <Col xs={8} className="px-0">
                                        <div className="client-side-bar-items">
                                            { state.user?.firstName ? <h6 className="side-bar-title my-1">{`${state.user?.firstName}`}</h6> : <Placeholder className="my-1 name-placeholder" width={3}/> }
                                            { state.user?.lastName ? <h6 className="side-bar-title my-1">{`${state.user?.lastName}`}</h6> : <Placeholder className="my-1 name-placeholder" width={4}/> }
                                            { state.user?.bin?.binNumber ? (<p className="side-bar-bin-number my-1">Bin: <span className="text-danger font-weight-bold">{state.user?.bin?.binNumber}</span></p>) : <Placeholder className="my-1 name-placeholder" width={2}/> }
                                        </div>
                                    </Col>

                                </Row>
                            </div>

                            <div className="mb-3 w-100">
                                <Row>
                                    <Col xs={4} md={4}>
                                        <h6 className="side-bar-info-title">Clarity ID</h6>
                                        {state.user?.clarityId ? <p className="side-bar-info-value">{state.user?.clarityId}</p> : <Placeholder width={2}/> }
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <h6 className="side-bar-info-title">Email</h6>
                                        { state.user?.emailAddress ? <p className="side-bar-info-value">{state.user?.emailAddress}</p> : <Placeholder width={8}/> }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} md={4}>
                                        <h6 className="side-bar-info-title">Phone Number</h6>
                                        { state.user?.phoneNumber? <p className="side-bar-info-value">{state.user?.phoneNumber}</p> : <Placeholder width={5}/>}
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <h6 className="side-bar-info-title">Referral</h6>
                                        { state.user?.referralInfoId ? <p className="side-bar-info-value"> {ReferralInfo[(state.user?.referralInfoId)]?.replaceAll("_", " ")} </p>: <Placeholder width={7}/> }
                                    </Col>
                                </Row>

                            </div>
                        </div>
                        <Row className="m-0 w-100 flex-column justify-content-between">

                            <EnrolPopUp show={state.modalShow} onHide={() => dispatch({ type: 'setModalShow', payload: false })} data={state.user}/>
                            { state.user && <div className="w-100">

                                {state.user?.accountStatusId === 1? (
                                    <div>
                                        <Link to={`/app/check-in-form/${id}`}>
                                            <Button size="sm" variant="outline-success w-100 mb-3">Check In</Button>
                                        </Link>
                                        <ClientCloseOut className="w-100 mb-3" binId={props.user?.bin?.binNumber} clientId={id} inventory={state.user.inventory}/>
                                    </div>
                                ):(
                                    <Button variant="primary w-100 mb-3" size="sm" onClick={() => dispatch({ type: 'setModalShow', payload: true })}>
                                        Re-Enroll
                                    </Button>
                                )}

                                <Link to={`/app/edit-client/${id}`}>
                                    <Button size="sm" variant="outline-dark w-100 mb-3">Edit Info</Button>
                                </Link>

                            </div>}

                            <Link to={`/app/clients/${id}`}>
                                <Button size="sm" variant="outline-dark btn-borderless text-dark w-100">View Full Profile</Button>
                            </Link>

                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ClientInfoPopUp;