import React, { useEffect, useReducer } from "react";
import { Endpoint } from "../api/base";
import { Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import ClientProfile from "../components/ClientProfile/ClientProfile";
import Loading from '../components/Loading'

const initialState = {data: {}, inventoryHistory: undefined}
function reducer (state, action)  {
  switch(action.type) {
    case 'setData':
      return {...state, data: action.payload}
    case 'setInventoryHistory':
      return {...state, inventoryHistory: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

function ClientProfilePage() {
  let { id } = useParams();
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState, undefined)
  const images = [state.data.imageUrls];

  const getInventoryHistory = () => {
    Endpoint.Inventory.history(id).then(res => {
      dispatch({ type: 'setInventoryHistory', payload: res.data })
    }).catch(e => {

    })
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Endpoint.Clients.get(id)
        dispatch({ type: 'setData', payload: response.data })
      } catch (err) {
        if(err.response) {
          console.log(err.response.data)
        }
      }
      
    };
    fetchData();
  }, [id]);

  return <>{state.data.id ?
        <div>
          {history.action !== 'POP' && <Button onClick={() => history.goBack()} className="back-btn" variant="dark">Back</Button>}
          <ClientProfile getInventoryHistory={getInventoryHistory} data={state.data} inventoryHistory={state.inventoryHistory} images={images} />
        </div>
     : <Loading height="calc(100vh - 70px)" />}</>;

}
export default ClientProfilePage;
