import React, { useEffect, useReducer } from "react";
import {Endpoint} from "../api/base";
import { Col, Row } from "react-bootstrap";
import Loading from '../components/Loading'
import ClientList from "../components/ClientList";
import ClientInfoPopUp from "../components/ClientInfoPopUp";

const initialState = {clientList: undefined, clientId: undefined, clientInfoPopUpShow: false}
function reducer (state, action)  {
    switch(action.type) {
        case 'setClientList':
            return {...state, clientList: action.payload}
        case 'setClientId':
            return {...state, clientId: action.payload}
        case 'setClientInfoPopUpShow':
            return {...state, clientInfoPopUpShow: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

function ClientPage(props) {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)

    useEffect(() => {
        const fetchData = async () => {
            const response = await Endpoint.Clients.all();
            dispatch({ type: 'setClientList', payload: response?.data })
        };
        fetchData();
    }, []);

    return (
        <Row className="m-0 flex-nowrap">
            <Col
                style={{minHeight: "calc(100vh - 70px)", flex: "1"}}
                className="m-0 pl-2 pr-2 pl-lg-0 pr-lg-3 py-4"
            >
                { state.clientList &&
                <ClientList
                    handleSelect={(client) => {
                        dispatch({ type: 'setClientId', payload: client?.clientId || undefined })
                        dispatch({ type: 'setClientInfoPopUpShow', payload: true })
                    }}
                    list={state.clientList}
                />
                }
                { state.ClientList?.length === 0 && "No Client Found" }

                { !state.clientList && <Loading height="calc(100vh - 150px)" /> }

            </Col>
            {state.clientId &&
            <ClientInfoPopUp
                status={state.clientInfoPopUpShow}
                onClose={() => dispatch({ type: 'setClientInfoPopUpShow', payload: false })}
                id={state.clientId}
            />
            }
        </Row>
    );
}
export default ClientPage;

