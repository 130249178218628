import React, { useEffect, useReducer } from "react";
import {OverlayTrigger, Button, Popover, Row, Col, Image} from 'react-bootstrap'
import { getAccessToken, logout } from "../../api/authProvider"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
// import { Link } from "react-router-dom";
import { WaitListModal } from '../WaitList/waitListModal'

function userPopoverReducer (state, action)  {
  switch(action.type) {
    case 'setUser':
      return {...state, user: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

export const UserPopover = props => {
  const [state, dispatch] = useReducer(userPopoverReducer, {user: {}}, undefined)

  useEffect(() => {
    getAccessToken().then(result => {
      dispatch({ type: 'setUser', payload: result.account })
    }).catch(err => {
      console.error(err)
    })
  }, [])
  
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={
        <Popover className="top-bar-popover" id={`popover-positioned-bottom`}>
          <Popover.Title as="h3">
            <Row className="m-0 align-items-center">
              <Col xs="auto" className="p-0 pr-3">
                <Image className="top-bar-avatar" src={state.user.photo || "/images/avatar-placeholder.svg"} roundedCircle />
              </Col>
              <Col xs={8} className="px-0">
                <h6 className="top-bar-user my-1">{state.user.name}</h6>
                <p className="top-bar-username my-1">{state.user.username}</p>
              </Col>
            </Row>
          </Popover.Title>
          <Popover.Content>
          <Button onClick={logout} size="sm" variant="outline-dark btn-borderless text-dark">Log Out <FontAwesomeIcon size="lg" icon={faSignOutAlt} /></Button>
          </Popover.Content>
        </Popover>
      }
    >
      <Button variant="outline-dark btn-borderless text-dark">{state.user.name}</Button>
    </OverlayTrigger>
  )
}

function topBarReducer (state, action)  {
  switch(action.type) {
    case 'setOpen':
      return {...state, open: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

export const TopBar = props => {
  const [state, dispatch] = useReducer(topBarReducer, {open: false}, undefined)
  const waitListPage = window.location.href.indexOf('wait-list') !== -1
  if (waitListPage){
    return (
      <Row xs={12} className="m-0 pt-5 px-3 justify-content-between top-bar-container">
        <Button  size="sm" onClick={() => dispatch({ type: 'setOpen', payload: true })} variant="dark px-5 d-none d-md-block" >Add To WaitList</Button>
        <WaitListModal isOpen={state.open} handleClose={() => dispatch({ type: 'setOpen', payload: false })}/>
        <UserPopover/>
      </Row>
    )
  }else{
    return (
      <>

        <Row xs={12} className="m-0 pt-3 px-3 justify-content-between top-bar-container">
          <div  style={{marginLeft: 'auto', marginRight: '0px' }}>
            <UserPopover/>
          </div>

        </Row>
      </>
    )
  }
  
}

