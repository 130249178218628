import {Col, Row} from "react-bootstrap";
import React from "react";
import {ColumnFilter} from "./";

export const TableHeader = (props) => {
    const { schema, handleFilters } = props;

    const handleChange = item => event => {
        const {name, label, type} = item;
        const { value } = event.target;
        handleFilters({ name, label, type, value });
    }

    return (
        <Row className="m-0 w-100 table-header">
            {schema.map((item, index) => {
                return (
                    <Col key={index} className={`${item.classes} p-2 py-3`}>
                        <p className="mb-2 text-left">{item.label}</p>
                        <ColumnFilter handleChange={handleChange} item={item} />
                    </Col>
                )
            })}
        </Row>
    )
}