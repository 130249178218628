import React, { useReducer } from "react"
import { Modal, Button, Spinner } from "react-bootstrap"
import { Endpoint } from "../../api/base";
import { useHistory } from "react-router-dom"
import toBase64 from "../../helpers/toBase64"

// CSS 
import "./index.css"
import Swal from "sweetalert2";


const ImageInput = props => {
    const {
        label="Image Search",
        size="sm",
        className = "btn-borderless text-dark",
        variant = "outline-dark",
        onChange = () => {console.error("no change controller!")}
    } = props;
    return (
        <Button size={size} className={`image-search-btn-wrapper ${className}`} variant={variant}>
            {label}
            <input accept="image/png, image/jpeg" type="file" name="upload_file" onChange={onChange} />
        </Button>
    )
}

const initialState = {image: null, open: null, loading: false}
function reducer (state, action)  {
    switch(action.type) {
        case 'setImage':
            return {...state, image: action.payload}
        case 'setOpen':
            return {...state, open: action.payload}
        case 'setLoading':
            return {...state, loading: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export const ImageSearch = props => {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    const history = useHistory()

    const handleImageSearch = (event) => {
        dispatch({ type: 'setLoading', payload: true })
        const formData = new FormData();
        formData.append("img", state.image.file);
        Endpoint.Clients.IdentifyClient(formData).then((response) => {
            dispatch({ type: 'setLoading', payload: false })
            if (response) {
              history.push("/app/clients/" + response.data.id);
            }
        }).catch((err) => {
            dispatch({ type: 'setLoading', payload: false })
            console.error(err)
            //Person not found
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
        });
    }

    const handleImage = async event  => {
        const file = event?.target?.files[0]
        const base64 = await toBase64(file).catch(e => Error(e));

        dispatch({ type: 'setImage', payload: {file, base64 } })
        dispatch({ type: 'setOpen', payload: true })
    }
    console.log(state.image)

    return (
        <React.Fragment>
            <ImageInput label="Image Search" onChange={handleImage}/>
            <Modal size="sm" className show={state.open} onHide={() => dispatch({ type: 'setOpen', payload: false })}>
                <Modal.Header className="py-2" closeButton>
                </Modal.Header>
                <Modal.Body className="position-relative overflow-hidden">
                    { state.image?.base64 && <img className="image-search-image" src={state.image?.base64} alt="selected"/> }
                    <Button onClick={handleImageSearch} size="sm" variant="success my-2 w-100">Submit</Button>
                    <ImageInput
                        variant="outline-danger"
                        className="w-100"
                        label="Retake"
                        onChange={handleImage}
                    />
                    { state.loading &&
                        <div className="row search-iamge-sppinner-holder m-0 align-items-center justify-content-center position-absolute w-100 h-100">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default ImageSearch;