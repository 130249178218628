import React, {useReducer} from "react";
import { Endpoint } from "../api/base";
import Camera from "react-html5-camera-photo";
import Loading from '../components/Loading'
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import "react-html5-camera-photo/build/css/index.css";
import * as Swal from "sweetalert2";

function reducer (state, action)  {
  switch(action.type) {
    case 'setLoading':
      return {...state, loading: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

function UploadPhotoPage() {
  const [state, dispatch] = useReducer(reducer, {loading: false}, undefined)
  const { clientId } = useParams();
  const history = useHistory();
  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  function handleTakePhoto(dataUri) {
    const formData = new FormData();
    formData.append("img", dataURItoBlob(dataUri));
    formData.append("id", clientId);
    dispatch({ type: 'setLoading', payload: true })
    Endpoint.Clients.UploadImage({id: clientId, data: formData}).then((response) => {
      if (response) {
        history.push("/app/clients/" + clientId);
      }
      dispatch({ type: 'setLoading', payload: false })
    }).catch((err) => {
      //Failed to update image
      dispatch({ type: 'setLoading', payload: false })
      Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
    });
  }
  return (
    <CenterAlign>
      {!state.loading?
      <Camera
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
      />
      : <Loading />
      }
    </CenterAlign>
  );
}

const CenterAlign = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export default UploadPhotoPage;
