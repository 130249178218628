import React from 'react'
import Spinner from "react-bootstrap/Spinner";

export default function Loading(props){
    return(
        <div style={{height: props.height ? props.height : '100vh'}} className="row w-100 m-0 height-100vh justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />{' '}
          {props.text}
        </div>
    )
}