import React, { useEffect, useMemo, useReducer } from "react";
import { formatToLocal } from '../../helpers/dateHelpers'
import { Row, Col } from "react-bootstrap";
import { Table, DATA_TYPES } from "../Table"
import WaitListSideBar from "./WaitListSideBar"
import { Endpoint } from "../../api/base";
import Loading from '../Loading'

// CSS
import "./index"
import Swal from "sweetalert2";

const schema = [
  {
    label: "First Name",
    name: "firstName",
    type: DATA_TYPES.STRING,
    formatter: (data) => <p className="text-left w-100 m-0">{data.firstName}</p>,
  },
  {
    label: "Last Name",
    name: "lastName",
    type: DATA_TYPES.STRING,
    formatter: (data) => <p className="text-left w-100 m-0">{data.lastName}</p>,
  },
  {
    label: "Phone Number",
    name: "contactNumber",
    type: DATA_TYPES.STRING,
  },
  {
    label: "Email",
    name: "email",
    type: DATA_TYPES.STRING,
  },
  {
    label: "Date-Time Added",
    name: "timeAdded",
    type: DATA_TYPES.DATE,
    formatter: (data) => (
        <p className="text-center w-100 m-0">{formatToLocal(data.datetime)}</p>
    ),
  },
];

const initialState = {selected: undefined, loading: false, list: undefined}
function reducer (state, action)  {
  switch(action.type) {
    case 'setSelected':
      return {...state, selected: action.payload}
    case 'setLoading':
      return {...state, loading: action.payload}
    case 'setList':
      return {...state, list: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

const WaitList = () => {
  const [state, dispatch] = useReducer(reducer, initialState, undefined)

  const getData = () => {
    dispatch({ type: 'setLoading', payload: true })
    Endpoint.WaitList.all().then(result => {
      dispatch({ type: 'setList', payload: result.data })
      dispatch({ type: 'setLoading', payload: false })
    }).catch(err => {
      //Failed to catch user profile
      dispatch({ type: 'setLoading', payload: false })
      console.error(err)
      Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
    })
  }

  useEffect(() => {
    getData()
  }, []);

  const waitList = useMemo(() => {
    return state.list?.map(item => ({
      ...item,
      fullName: `${item.firstName} ${item.lastName}`
    }))
  }, [state.list])

  if(!state.loading) {
    return (
        <Row className="m-0 flex-nowrap w-100">
          <Col
              className="m-0 pl-2 pr-2 pl-lg-0 pr-lg-3 py-4 wait-list-main-table"
          >
            <Table
                onSelect={(data) => dispatch({ type: 'setSelected', payload: data })}
                list={waitList || []}
                uniqeKeyName="id"
                schema={schema}
            />
          </Col>

          { state.selected &&
          <Col lg={3} className="pl-0 wait-list-side-bar-outer-container">
            <WaitListSideBar
                onClose={() => dispatch({ type: 'setSelected', payload: undefined })}
                getData={getData}
                data={state.selected}
            />
          </Col>
          }

        </Row>
    );
  }
  return <Loading height="calc(100vh - 70px)" />
}

export default WaitList;