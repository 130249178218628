import React, { useImperativeHandle, useReducer } from "react";
import { Row } from "react-bootstrap";
import { filterArray, chipsFilter, Pagination, TableHeader, TableRow, SearchBar } from "./";
import { PAGE_OPTION } from "./";

import "./index.css"

function reducer (state, action)  {
  switch(action.type) {
    case 'setQuery':
      return {...state, query: action.payload}
    case 'setFilters':
      return {...state, filters: action.payload}
    case 'setPageSize':
      return {...state, pageSize: action.payload}
    case 'setCurrentPage':
      return {...state, currentPage: action.payload}
    default:
      throw new Error("Something went wrong!"+action.type)
  }
}

export const Table = React.forwardRef((props, ref) => {
  const { list = [], uniqeKeyName = "id", schema = [] } = props;
  const { pageSizeOptions = PAGE_OPTION, onSelect = () => {} } = props;
  const [state, dispatch] = useReducer(reducer,
      {
        query: "",
        filters: {},
        pageSize: pageSizeOptions[0]?.value || 10,
        currentPage: 0
      },
      undefined)
  useImperativeHandle(ref, () => ({
    resetFilters() {
      dispatch({ type: 'setFilters', payload: {} })
    }

  }));

  const handleFilters = filterParams => {
    const {name = "", value = "" } = filterParams
    let filtersCopy = { ...state.filters };

    if(!value) {
      delete filtersCopy[name]
    } else {
      filtersCopy = {...filtersCopy, [name]: filterParams}
    }
    setCurrentPage(0);
    dispatch({ type: 'setFilters', payload: filtersCopy })
  }
  const removeFilters = name => {
    let filtersCopy = { ...state.filters };
    delete filtersCopy[name];

    const item = schema.filter(item => item.name === name)[0];
    if(item?.ref?.current) item.ref.current.value = "";

    setCurrentPage(0);
    dispatch({ type: 'setFilters', payload: filtersCopy });
  }

  const filteredArray = filterArray(list, state.query);
  const filterdByChips = chipsFilter(filteredArray, state.filters);
  const currentPageArray = filterdByChips?.filter((item, index) => {
    return  index >= state.currentPage * state.pageSize &&  index < state.currentPage * state.pageSize + state.pageSize
  })

  const handleSearch = (event) => {
    dispatch({ type: 'setCurrentPage', payload: 0 })
    dispatch({ type: 'setQuery', payload: event.target.value })
  }

  const setPageSize = (value) => {
    dispatch({ type: 'setPageSize', payload: value })
  }
  const setCurrentPage = (value) => {
    dispatch({ type: 'setCurrentPage', payload: value })
  }

  return (
      <React.Fragment>
        <Row className="m-0 table-body">
          <SearchBar removeFilters={removeFilters} filters={state.filters} onChange={handleSearch}/>
          <TableHeader handleFilters={handleFilters} schema={schema}/>
          { currentPageArray?.map(item => (
              <TableRow
                  onSelect={onSelect}
                  key={item[uniqeKeyName]}
                  uniqeKeyName={uniqeKeyName}
                  data={item}
                  schema={schema}
              />
          ))}
        </Row>
        <Pagination
            dataNumber={filterdByChips?.length || 0}
            pageSizeOptions={pageSizeOptions}
            pageSize={state.pageSize}
            setPageSize={setPageSize}
            currentPage={state.currentPage}
            setCurrentPage={setCurrentPage}
        />
      </React.Fragment>

  )
});

export default Table;

