import React, { useReducer } from "react";
import { Col, Row, Button, Spinner} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { formatToLocal } from '../../helpers/dateHelpers'
import { WaitListModal } from './waitListModal' 
import { ConfirmModal } from './ConfirmModal'
import { Endpoint } from "../../api/base";
import "./index.css";
import Swal from "sweetalert2";

const Placeholder = ({ width = 3, className }) =>  <div className={`side-bar-placeholder ${className}`} style={{width: `${width * 20}px`}}/>

const initialState = {open: false, showDeleteConfirm: false, loading: false}
function reducer (state, action)  {
	switch(action.type) {
		case 'setOpen':
			return {...state, open: action.payload}
		case 'setShowDeleteConfirm':
			return {...state, showDeleteConfirm: action.payload}
		case 'setLoading':
			return {...state, loading: action.payload}
		default:
			throw new Error("Something went wrong!")
	}
}

export const ClientInfoSideBar = props => {
	const { onClose, data, getData = () => {} } = props;
	const [state, dispatch] = useReducer(reducer, initialState, undefined)

	const handleDelete = () => {
		dispatch({ type: 'setLoading', payload: true })
		Endpoint.WaitList.delete(data.id).then(() => {
			dispatch({ type: 'setShowDeleteConfirm', payload: false })
			getData()
			dispatch({ type: 'setLoading', payload: false })
		}).catch(err => {
			//failed to delete!
			Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
			console.error(err)
			dispatch({ type: 'setLoading', payload: false })
		})
	}
  	
	return (
		<Row className="m-0 pt-0 pb-0 h-100 w-100">
			<Col className="w-100 pl-0 wait-list-side-bar-container p-3 row m-0">
				<div className="w-100">

					<div className="row m-0 mb-4 w-100 justify-content-between align-items-center">
						<Button onClick={onClose} variant="outline-dark btn-borderless text-dark">
							<FontAwesomeIcon icon={faArrowRight} />
						</Button>

						{ !data && <Spinner size="sm" animation="border" variant="success" />}
					</div>

					<div className="mb-3 w-100">
						<Row xs="auto" className="m-0 p-0 pr-3 flex-nowrap">
		          		<Col xs={12} className="px-0">
		                { data?.fullName ? <h6 className="wait-list-side-bar-title my-1">{`${data?.fullName}`}</h6> : <Placeholder className="my-1 name-placeholder" width={3}/> }
		              </Col>

	              </Row>
					</div>

					<div className="mb-3 w-100">

	               <h6 className="wait-list-side-bar-info-title">Phone Number</h6>
	               { data?.contactNumber? <p className="wait-list-side-bar-info-value">{data?.contactNumber}</p> : <Placeholder width={5}/>}

	               <h6 className="wait-list-side-bar-info-title">Email</h6>
	               { data?.email ? <p className="wait-list-side-bar-info-value">{data?.email}</p> : <Placeholder width={8}/> }

	               <h6 className="wait-list-side-bar-info-title">Date Added</h6>
	               {data?.datetime ? <p className="wait-list-side-bar-info-value text-danger">{formatToLocal(data?.datetime)}</p> : <Placeholder width={2}/> }

								 <h6 className="wait-list-side-bar-info-title">Notes</h6>
	               { data?.email ? <p className="wait-list-side-bar-info-value">{data?.notes}</p> : <Placeholder width={8}/> }
					</div>
				</div>
				<Row className="m-0 w-100 flex-column justify-content-between">
					<div className="w-100">
				  		<Button size="sm" onClick={() => dispatch({ type: 'setOpen', payload: true })} variant="dark w-100 mb-3">Edit Info</Button>
						<Link to={`/app/wait-list/intake/${data.id}`}>
							<Button size="sm" variant="success w-100 mb-3">Initiate Intake</Button>
						</Link>
				  		<Button size="sm" onClick={() => dispatch({ type: 'setShowDeleteConfirm', payload: true })} variant="outline-danger btn-borderless text-danger w-100">Remove From Wait List</Button>
					</div>
				</Row>
				<WaitListModal getData={getData} id={data.id} data={data} isOpen={state.open} handleClose={() => dispatch({ type: 'setOpen', payload: false })}/>
				<ConfirmModal loading={state.loading} text="Are you sure you want to delete This person from wait list?" isOpen={state.showDeleteConfirm} handleClose={() => dispatch({ type: 'setShowDeleteConfirm', payload: false })} onConfirm={handleDelete}/>
			</Col>
		</Row>
	)	
}

export default ClientInfoSideBar;