
import React, { useReducer } from "react";
import {Alert, Badge, Button, Col, Modal, Row, Spinner, Table} from 'react-bootstrap';
import {Field, FieldArray, Form, Formik} from "formik";
import {formatToLocal} from "../../helpers/dateHelpers";
import {useOptionsState} from "../../helpers/optionsContext";
import {Endpoint} from "../../api/base";
import Swal from "sweetalert2";

function reducer (state, action)  {
    switch(action.type) {
        case 'setLoading':
            return {...state, loading: action.payload}
        case 'setSubmit':
            return {...state, submit: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export default function MoveToGrayBin(props) {
    const { show, handleClose } = props
    const { CheckInType, InventoryItemType } = useOptionsState();
    const {xs = 12, sm = 12, md = 12, lg = 12, xl = 12} = props;
    const [state, dispatch] = useReducer(reducer, {loading: false, submit: true}, undefined)

    const handleSubmit = async (values)=>{
        dispatch({ type: 'setLoading', payload: true });

        try {
            const inventoryPayload = {
                "ClientId": props.clientId,
                "binNumber": props.binId,
                "grayInventory": values.inventory?.filter(t=>t.isMoved ==='true')
            }
            await Endpoint.GrayBin.write(inventoryPayload);

            dispatch({ type: 'setLoading', payload: false });
            handleClose(true);
        } catch (err) {
            dispatch({ type: 'setLoading', payload: false })
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
        }
    }
    return (
        <>
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Move Inventory items to Gray Bin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Formik
                            initialValues={{
                                inventory:  props?.inventory?.inventoryList,
                                isEmpty: false,
                                checkinType: Object.keys(CheckInType||{})[0],

                            }}
                            onSubmit={handleSubmit}
                        >
                            {({submitForm, values, handleChange, setFieldValue}) => (
                                <Form>
                                    <Row className="align-items-center flex-column py-3 m-0">

                                        <FieldArray name="inventory">
                                            {(inventoryActions) => (
                                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                                    <Row>
                                                        {values.isEmpty ?
                                                            <Alert variant="warning" className="w-100 mx-3 my-0" dismissible>
                                                                <p className="my-0">Are you sure you want to <span className="font-weight-bold">Empty</span> the bin!</p>
                                                            </Alert>
                                                            :
                                                            (
                                                                <>
                                                                    <Table >
                                                                        <thead>
                                                                        <tr>
                                                                            {/* <th>#</th> */}
                                                                            <th>Item Type</th>
                                                                            <th>Item Quantity</th>
                                                                            <th>Description</th>
                                                                            {/*<th>Employee</th>*/}
                                                                            <th>Date</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {values?.inventory?.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    {/* <td>{item.id}</td> */}
                                                                                    <td className="p-2 select">
                                                                                        <Field
                                                                                            className="form-control form-control-sm"
                                                                                            as="select"
                                                                                            size="sm"
                                                                                            name={`inventory.${index}.InventoryItemTypeId`}
                                                                                            disabled={true}
                                                                                            defaultValue={item.inventoryItemTypeId}
                                                                                        >
                                                                                            {Object.keys(InventoryItemType).map( inType =>
                                                                                                <option key={inType} value={inType} label={InventoryItemType[inType]}/>
                                                                                            )}
                                                                                        </Field>

                                                                                    </td>
                                                                                    <td className="p-2">
                                                                                        <Field
                                                                                            className="form-control form-control-sm"
                                                                                            type="number"
                                                                                            name={`inventory.${index}.quantity`}
                                                                                            disabled={true}
                                                                                            placeholder="Quantity"
                                                                                        />
                                                                                    </td>
                                                                                    <td className="p-2">
                                                                                        <Field
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            name={`inventory.${index}.description`}
                                                                                            disabled={true}
                                                                                            placeholder="description"
                                                                                        />
                                                                                    </td>
                                                                                    {/*<td>{item?.employee || "-"}</td>*/}
                                                                                    <td>{formatToLocal(item.date)}</td>
                                                                                    <td className="action-column-items px-2 py-2 text-right" >
                                                                                        {item.isMoved? <Badge variant="warning">Moved</Badge>
                                                                                            :
                                                                                            <Button
                                                                                                onClick={() => {
                                                                                                    //dispatch({ type: 'setSubmit', payload: true });
                                                                                                    inventoryActions.push({
                                                                                                        InventoryItemTypeId: item.inventoryItemTypeId,
                                                                                                        quantity: item.quantity,
                                                                                                        description: item.description,
                                                                                                        isMoved: "true"
                                                                                                    })
                                                                                                    inventoryActions.remove(index)

                                                                                                }}
                                                                                                size="sm"
                                                                                                variant="outline-danger btn-borderless text-danger w-100"
                                                                                            >
                                                                                                Move
                                                                                            </Button>}
                                                                                    </td>
                                                                                </tr>
                                                                            )})}
                                                                        </tbody>

                                                                    </Table>
                                                                </>
                                                            ) }
                                                    </Row>
                                                </Col>
                                            )}
                                        </FieldArray>
                                        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="px-0">
                                            <Row className="justify-content-center m-0">
                                                <Button
                                                    className="px-5 check-in-form-submit-button"
                                                    variant="success"
                                                    type="submit"
                                                    disabled={state.loading || !state.submit}
                                                >
                                                    {state.loading ? <Spinner size="sm" animation="border" variant="primary" />: "Submit"}
                                                </Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}


