import React from "react";

var OptionsStateContext = React.createContext();
var OptionsDispatchContext = React.createContext();

function OptionsReducer(state, action) {
    switch (action.type) {
        case "OPTIONS_SET":
            return Object.assign({}, state, action.payload);
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function OptionsProvider({ children }) {
    var [state, dispatch] = React.useReducer(OptionsReducer, 
        {
            "Location":{
                "1":"Storage Connect"
            },
            "Ethnicity": {
                "1": "Hispanic_or_Latino",
                "2": "Not_Hispanic_or_Latino",
                "3": "No_Response"
            },
            "Race": {
                "1": "White",
                "2": "Hispanic"
            },
            "ReferralInfo": {
                "1": "City_Environmental_Services",
                "2": "SDPD",
                "3": "All_Other_City_Departments",
                "4": "Think_Dignity",
                "5": "All_Other_Service_Providers",
                "6": "No_Referral_was_Made"
            },
            "TermsOfServiceLength": {
                "1": 30,
                "2": 60
            },
            "CheckInType": {
                "1": "Phone",
                "2": "WalkIn",
                "3": "Letter",
                "4": "Email",
                "5": "Courtesy",
                "6": "Checkin"
            },
            "InventoryItemType": {
                "1": "Blanket",
                "2": "Dress",
                "3": "Jacket",
                "4": "Pants",
                "5": "Paperwork",
                "6": "Pillow",
                "7": "Sleeping_Bag",
                "8": "Socks",
                "9": "Sweater",
                "10": "Shoes",
                "11": "Towel",
                "12": "Tarp",
                "13": "Tent",
                "14": "Underwear",
                "15": "Other"
            },
            "AccountStatus": {
                "1": "Active",
                "2": "Closed_Out_Client",
                "3": "Pre_Close_Out_Abandoned_Bin",
                "4": "Closed_Out_Abandoned_Bin",
                "5": "Closed_Out_5_Day_Empty_Bin"
            },
            "HistoryAction": {
                "1": "Add",
                "2": "Remove",
                "3": "Update"
            },
            "StaticCloseoutReasons": {
                "1": "Empty Bin (5 Empty Days)",
                "2": "Rental by client/No ongoing houing subsidy",
                "3": "Employment",
                "4": "Employment/Housing",
                "5": "Moving in with Relatives",
                "6": "Reunification with family members",
                "7": "Not needed anymore",
                "8": "Relocation",
                "9": "No explanation provided",
                "10": "Other",
            },
            "StaticReferredOutCategories": [
                "Basic Needs",
                "Food",
                "Transportation",
                "Health Care",
                "Income Support & Employment",
                "Mental Health & Substance Use Disorder Service",
                "Homeless Shelter",
                "At Risk/ Homeless Housing Related Assistance Programs",
                "Housing Search & Information"
            ],
            "CloseOutReasons": {
                "Referral Out Code": [
                    "Basic Needs",
                    "Food",
                    "Transportation",
                    "Health Care",
                    "Income Support & Employment",
                    "Mental Health & Substance Use Disorder Service",
                    "Homeless Shelter",
                    "At Risk/ Homeless Housing Related Assistance Programs",
                    "Housing Search & Information"
                ],
                "Term of Service Expiration Abandoned Bin": [
                    "Abandoned Bin (Expiration date has came without check-in)",
                    "Disposal (31st or 61st date since the bin has been in the abandoned status"
                ],
                "Empty Bin Policy Expiration": [
                    "5 Day Empty Bin – Exit interview not conducted (add comment to record)"
                ],
                "Client Closeout": [
                    "Rental by client/no ongoing housing subsidy",
                    "Employment",
                    "Employment/Housing",
                    "Moving in with Relatives",
                    "Reunification with family members",
                    "Not needed anymore",
                    "Relocation",
                    "No explanation provided",
                    "Other"
                ]
            }
        }
    );
    return (
        <OptionsStateContext.Provider value={state}>
            <OptionsDispatchContext.Provider value={dispatch}>
                {children}
            </OptionsDispatchContext.Provider>
        </OptionsStateContext.Provider>
    );
}

function useOptionsState() {
    var context = React.useContext(OptionsStateContext);
    if (context === undefined) {
        throw new Error("useOptionsState must be used within a OptionsStateContext");
    }
    return context;
}

function useOptionsDispatch() {
    var context = React.useContext(OptionsDispatchContext);
    if (context === undefined) {
        throw new Error("useOptionsDispatch must be used within a OptionsStateContext");
    }
    return context;
}

function setOptions(dispatch, payload) {
    dispatch({
        type: "OPTIONS_SET",
        payload
    });
}

export { OptionsProvider, useOptionsState, useOptionsDispatch, setOptions };

