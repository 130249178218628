import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { OptionsProvider } from './helpers/optionsContext'

ReactDOM.render(
  <React.StrictMode>
    <OptionsProvider>
      <App />
    </OptionsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
