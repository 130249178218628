import { DATA_TYPES, COMPARATOR } from "./"


export const isDateValid = date => {
  const convertedDate = new Date(date);
  return date !== null && !isNaN(convertedDate.getTime())
}

const compareDate = (firstDate, secondDate, operator) => {
    switch(operator){
      case COMPARATOR.GT:
        return new Date(secondDate).toISOString() < new Date(firstDate).toISOString();
      case COMPARATOR.LT:
        return new Date(secondDate).toISOString() > new Date(firstDate).toISOString();
      case COMPARATOR.EQ: {
        const searchDate = new Date(new Date(secondDate)?.toLocaleDateString()).toISOString();
        const itemDate = new Date(new Date(firstDate)?.toLocaleDateString()).toISOString();
        return searchDate === itemDate;
      }
      default:
        return true;
    }
}

const simpleFilter = (array = [], chip = {}) => array.filter(item =>  {
  const keyWordLowerCase = chip?.value?.toString()?.toLowerCase();

  if(!chip.name) return true
  switch(chip?.type){

    case DATA_TYPES.DATE: {
      const [searchDate, operator] = chip?.value;
      return compareDate(item[chip?.name], searchDate, operator)
    }

    default: 
      return item[chip?.name]?.toString()?.toLocaleLowerCase()?.includes(keyWordLowerCase)
  }
  
});


export const chipsFilter = (array = [], chips = {}) => {
  const keys = Object.keys(chips)
  let resultArray = array;
  keys.forEach(key => {
    resultArray = simpleFilter(resultArray, chips[key])
  })
  return resultArray;
}

export const filterArray = (array = [], query = "") => {
  const keyWordLowerCase = query?.toString()?.toLowerCase();

  return array.filter(item => {
    let result = false;
    const keys = Object.keys(item);

    keys.forEach(key => {
      result = result || item[key]?.toString()?.toLocaleLowerCase()?.includes(keyWordLowerCase)
    })
    
    return result;
  });
};