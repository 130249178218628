import axios from "axios";
import { getAccessToken } from './authProvider'

export const baseURL = process.env.REACT_APP_BASE_URL
//console.log(process.env)
// ?process.env.BASE_URL:"https://sc-face-dev.azurewebsites.net/api/";
// export const baseURL = "https://mhstestbackend.azurewebsites.net/api/";
// export const baseURL = "https://mhsbackend.azurewebsites.net/api/";
// const baseURL = "https://sc-face.azurewebsites.net/api/";

axios.interceptors.request.use(async (config) => {
  const token = await getAccessToken().catch(e => { console.log(e) })
  const extendedConfig = {
    headers: { Authorization: `Bearer ${token?.accessToken}` }
  }
  if(config?.data?.employee){
    extendedConfig.data = Object.assign({}, config.data, { employee: token.account})
  }
  const newConfig = Object.assign({}, config, extendedConfig)
  return newConfig;
}, function (error) {
  return Promise.reject(error);
});
axios.defaults.baseURL = baseURL

export const Endpoint = {
  AppConstants: {
    get: _ => axios.get(`appconstants`),
  },
  Clients: {
    all: _ => axios.get(`client/`),
    get: id => axios.get(`client/${id}`),
    new: data => axios.put(`client`, data),
    update: data => axios.post(`client`, data),
    delete: id => axios.delete(`client/${id}`),
    UploadImage: ({id, data}) => axios.post(`client/UploadImage/${id}`, data),
    getImage: id => axios.get(`client/DownloadImage/${id}`,{responseType: 'blob'}),
    checkin: data => axios.post(`client/checkin`, data),
    reEnroll: data => axios.put(`client/reenroll`, data),
    IdentifyClient: data => axios.post(`client/IdentifyClient/1`, data),
    Notes: {
      get: id => axios.get(`client/${id}/notes`),
      new: ({id, data}) => axios.post(`client/${id}/notes`, data),
    },
    CloseOut: {
      update: data => axios.post(`client/closeout`, data),
      history: id => axios.get(`client/${id}/closeout/history`),
    },
    CheckIn: {
      update: ({binId, id, data}) => axios.post(`checkin/${binId}/${id}`, data),
      delete: data => axios.delete(`client/checkin`, { data: data }),
    },
    retrieval: id => axios.post(`client/retrieval/${id}`),
    disposal: id => axios.post(`client/disposal/${id}`)
  },
  GrayBin: {
    get: id => axios.get(`Inventory/graybin/${id}`),
    write: data => axios.put(`Inventory/graybin`, data),
  },
  WaitList: {
    all: _ => axios.get(`waitlist`),
    get: id => axios.get(`waitlist/${id}`),
    new: data => axios.put(`waitlist`, data),
    update: data => axios.post(`waitlist`, data),
    delete: id => axios.delete(`waitlist/${id}`),
  },
  Inventory: {
    update: data => axios.post(`Inventory`, data),
    history: id => axios.get(`Inventory/history/${id}`),
    delete: id => axios.delete(`Inventory/history/${id}`),
  },
  BinList: {
    all: _ => axios.get(`binlist`),
    available: _ => axios.get(`binlist/available`),
  },
}