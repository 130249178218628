import React, {useEffect, useReducer} from "react";
import NotesList from "../NotesList";

const initialState = { notes: [], }
function reducer (state, action)  {
    switch(action.type) {
        case 'setNotes':
            return {...state, notes: action.payload};
        default:
            throw new Error("Something went wrong!");
    }
}

export const ClientGrayBinNotes = ({ clientId }) => {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)



    const setNotes = (notes) => {
        dispatch({ type: 'setNotes', payload: notes });
    }

    return (
        <>
            <NotesList setNotes={setNotes} title="Gray Bin Notes" noteTypeId={4} clientId={clientId}/>
        </>
    );

}

export default ClientGrayBinNotes;
