import * as msal from "@azure/msal-browser";
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/4ec6551d-32a7-4fa5-85b0-c9c89e04ca08',
        clientId: 'f99c1762-96dc-4e21-903e-10ad20c7e9d2',
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case msal.LogLevel.Error:
                        console.error(message);
                        return;
                    case msal.LogLevel.Info:
                        console.info(message);
                        return;
                    case msal.LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case msal.LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.log(message)
                        return
                }
            }
        }
    }
};

const authRequest = {
    scopes: [
        'https://sc-face-dev.azurewebsites.net/user_impersonation',
        'User.Read'
    ]
}

const MSALObj = new msal.PublicClientApplication(config);
export default MSALObj

window.MSALObj = MSALObj

export const handleRedirectPromise = async ()=>{
    return MSALObj.handleRedirectPromise()
}



export const login = async ()=>{
    return MSALObj.loginRedirect(authRequest)
}

const getUserName = ()=>{
    let username;
    const currentAccounts = MSALObj.getAllAccounts();
    if (currentAccounts === null) {
        return null;
    } else if (currentAccounts.length > 1) {
        //TODO:
        console.warn("Multiple accounts detected.");
        return null
    } else if (currentAccounts.length === 1) {
        username = currentAccounts[0].username;
    }

    return username
}

export const getAccessToken = async ()=>{
    const username = getUserName()
    if(!username) return 
    var currentAccount = MSALObj.getAccountByUsername(username);
    var silentRequest = {
        ...authRequest,
        account: currentAccount,
        forceRefresh: false
    };

    return await MSALObj.acquireTokenSilent(silentRequest).catch(async (error) => {
        if (error.name === "InteractionRequiredAuthError") {
            // fallback to interaction when silent call fails
            return await MSALObj.acquireTokenRedirect(authRequest)
        }
    });
}

export const logout = () =>{
    const username = getUserName()

    const logoutRequest = {
        account: MSALObj.getAccountByUsername(username),
        redirectUri: window.location.origin,
        // postLogoutRedirectUri: window.location.origin,
    };

    MSALObj.logout(logoutRequest);
}
