import {useReducer} from "react";

// #############################################################################
// this function will handle input changes
// #############################################################################

function reducer (state, action)  {
  switch(action.type) {
    case 'setValue':
      return {...state, value: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

export function useFormInput(initialValue = ""){
  const [state, dispatch] = useReducer(reducer, {value: initialValue}, undefined)
  const onChange = event => dispatch({ type: 'setValue', payload: event.target.value })
  let value = state.value;
  return  {value, onChange}
}