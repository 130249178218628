import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Chip} from "./";

export const SearchBar = props => {
    const { onChange = () => {}, removeFilters, type, placeholder = "", filters = {} } = props;
    return (
        <Row className="m-0 py-0 px-3 w-100 table-search-bar align-items-center">
            { Object?.keys(filters)?.map(key => <Chip removeFilters={removeFilters} key={filters[key]?.name} item={filters[key]}/>) }

            <Col xs="auto" className="row m-0 p-0 position-relative align-items-center">

                <FontAwesomeIcon className="position-absolute" icon={faSearch} />

                <input
                    className="pl-4 position-relative"
                    placeholder={placeholder || "Search..."}
                    onChange={onChange}
                    type={ type || "text"}
                />

            </Col>

        </Row>
    )
}