import {createMuiTheme} from "@material-ui/core";

export const customPickerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000',
            light:  '#000000',
            dark:  ''
        },
        secondary: {
            main: '#000000',
        },
    },
})