import React from "react";
import { FieldArray, Field } from "formik";
import { Button, Row, Alert, Col } from 'react-bootstrap';
import { useOptionsState } from '../../helpers/optionsContext'

// css
import "./index.css"


const ElementAlert = props => (
    <Row className="m-0 py-3 w-100">
      <Alert className="m-0 w-100" variant="warning">Individual has <span className="font-weight-bold">5 days</span> to add an item or else they will lose the bin</Alert>
    </Row>
)

export const InventoryList = props => {
  const { values, setFieldValue } = props;
  const { InventoryItemType } = useOptionsState()


  const add = inventoryActions => event => {
    inventoryActions.push({
        isNew: true,
        Description: "",
        InventoryItemTypeId: Object.keys(InventoryItemType)[0],
        quantity: 1,
        ListAction: 1
    })
  }

  return (
      values.addInventory === "true" ? (
              <FieldArray name="inventory">
                {(inventoryActions) => (
                    <Row className="m-0 flex-column-reverse flex-md-column py-3 w-100">
                      <Row className="m-0 pb-2 align-items-center font-weight-bold w-100">
                        <Col className="d-none d-md-block" xs={1}>#</Col>
                        <Col className="d-none d-md-block" xs={2}>Item Type</Col>
                        <Col className="d-none d-md-block" xs={2}>Item Quantity</Col>
                        <Col className="d-none d-md-block" xs={5}>Description</Col>
                        <Col xs={12} md={2} className="row m-0 px-0 px-md-3 justify-content-end">
                          <Button size="sm" variant="outline-success" className="w-100 btn-borderless text-success" onClick={add(inventoryActions)}>+ Add Item</Button>
                        </Col>
                      </Row>
                      <Row className="m-0 w-100 inventory-list-rows">
                        {values?.inventory?.map((item, index) => {
                          return (
                              <Row key={index} className="m-0 mb-1 w-100 py-2 align-items-center inventory-list-row-container">
                                <Col className="py-2 py-md-0 text-center text-md-left" xs={12} md={1}>
                                  {index + 1}
                                </Col>
                                <Col className="py-2 py-md-0" xs={12} md={2}>
                                  <label htmlFor="inventory-type" className="field_element_label d-block d-md-none">Item Type</label>
                                  <Field
                                      id="inventory-type"
                                      className="form-control"
                                      as="select"
                                      name={`inventory.${index}.InventoryItemTypeId`}
                                  >
                                    {Object.keys(InventoryItemType).map(typeKey =>
                                        <option key={typeKey} value={typeKey} label={InventoryItemType[typeKey]} />
                                    )}
                                  </Field>

                                </Col>
                                <Col className="py-2 py-md-0" xs={12} md={2}>
                                  <label htmlFor="inventory-type" className="field_element_label d-block d-md-none">Item Type</label>
                                  <Field
                                      className="form-control form-control-sm"
                                      type="number"
                                      name={`inventory.${index}.quantity`}
                                      onChange={e => {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                        if (regex.test(value.toString())) {
                                          setFieldValue(`inventory.${index}.quantity`, value);
                                        }
                                      }}
                                      placeholder="Quantity"
                                      disabled={!item.isNew}
                                  />
                                </Col>
                                <Col xs={12} md={5}>
                                  <label htmlFor="inventory-description" className="field_element_label d-block d-md-none">Description</label>
                                  <Field
                                      id="inventory-description"
                                      className="form-control"
                                      type="text"
                                      name={`inventory.${index}.Description`}
                                      placeholder="description"
                                  />
                                </Col>
                                <Col className="py-2 py-md-0" xs={12} md={2} >
                                  <Button size="sm" className="w-100 w-md-100 btn-borderless text-danger" onClick={() => { inventoryActions.remove(index) }} variant="outline-danger">Remove</Button>
                                </Col>
                              </Row>
                          )
                        })}
                      </Row>
                    </Row>
                )}
              </FieldArray>
          )
          :
          <ElementAlert/>
  )
}