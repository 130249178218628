import React from "react";
import {Col, Row, Form} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export const Pagination = props => {
  const { pageSize = 10, currentPage = 0 } = props;
  const {setPageSize, setCurrentPage} = props;
  const { pageSizeOptions, dataNumber } = props;

  const calculateNumberOfPages = (dataNumber, pageSize) => {
    const numberOfPage = parseInt(dataNumber / pageSize)
    const hasRemainder = dataNumber % pageSize > 0;
    return hasRemainder ? numberOfPage + 1 : numberOfPage;
  }

  const generateButtonsNumbers = (dataNumber, pageSize) => {
    const lastPage = calculateNumberOfPages(dataNumber, pageSize) - 1;
     return [
      (currentPage) !== 0 ? 0 : null,
      (currentPage - 2) > 0 ? "..." : null,
      (currentPage - 1) > 0 ? (currentPage - 1) : null,
      (currentPage),
      (currentPage + 1) < lastPage ? (currentPage + 1) : null,
      (currentPage + 2) < (lastPage) ? "..."  : null,
      (currentPage) < (lastPage) ? (lastPage)  : null,
    ];
  }

  const handlePageSize = event => {
    //setPageSize(parseInt(event.target.value));
      //setPageSize({ type: 'setPageSize', payload: parseInt(event.target.value) })
      setPageSize(parseInt(event.target.value))
      setCurrentPage(0)
  }

  const pageButtons = (dataNumber, pageSize) => {
    const buttons = generateButtonsNumbers(dataNumber, pageSize);
    const lastPage = calculateNumberOfPages(dataNumber, pageSize) - 1;

    return (
      <React.Fragment>

      <button onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}>
        <FontAwesomeIcon icon={faArrowLeft}/>
      </button>

      { buttons.map((item, index) => {

        if(!isNaN(item) && item !== null)
          return(
            <button
              key={index}
              onClick={() => {setCurrentPage(item)}}
              className={index === 3 ? "table-pagination-current-page": ""}
            >
              {item + 1}
            </button>
          )

          else if(item === "...") return <span key={index}>...</span>; 

          else return null;
        
      })

      }

      <button onClick={() => setCurrentPage(Math.min(currentPage + 1, lastPage))}>
        <FontAwesomeIcon icon={faArrowRight}/>
      </button>
                
    </React.Fragment>
    )
  }

  return (
    <Row className="m-0 py-2 w-100">
      <Col xs="auto" className="px-1">
      <Form.Control value={pageSize} onChange={handlePageSize} size="sm" as="select">
          {pageSizeOptions?.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </Form.Control>
      </Col>
      <Row className="p-0 m-0 justify-content-center table-pagination-button-container">{pageButtons(dataNumber, pageSize)}</Row>
    </Row>
  )

}
