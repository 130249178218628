
export const DATA_TYPES = {
    DATE: "DATE",
    NUMBER: "NUMBER",
    STRING: "STRING",
    BOOL: "BOOL",
    OPTION:"OPTION"
}

export const COMPARATOR = {
    LT: "<",
    GT: ">",
    EQ: "="
}

export const COMPARATOR_NAME = {
    "<": "less then",
    ">": "more then",
    "=": "same as",
}

export const COMPARATOR_NAME_FOR_DATE = {
    "<": "before",
    ">": "after",
    "=": "at",
}

export const PAGE_OPTION = [
    {label: "10", value: 10},
    {label: "20", value: 20},
    {label: "30", value: 30},
    {label: "40", value: 40},
]