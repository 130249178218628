import React, { useEffect, useReducer } from "react";
import { Formik, Field, Form } from "formik";
import { Endpoint } from "../../api/base";
import { Button, Modal, Row, Col, Container } from 'react-bootstrap';
import { useOptionsState } from '../../helpers/optionsContext';
import Select from 'react-select';
import Swal from "sweetalert2";
import {MuiThemeProvider} from "@material-ui/core";
import {customPickerTheme} from "../customPickerTheme";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function reducer (state, action)  {
  switch(action.type) {
    case 'updateBinList':
      return {...state, binList: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

const EnrolPopUp = props => {
  const { TermsOfServiceLength, ReferralInfo, ReferralOutCode } = useOptionsState()
  const [state, dispatch] = useReducer(reducer, {binList: []}, undefined)

  let id = props.data?.id
  const currentUser = props.data

  useEffect(() => {
    const fetchData = async () => {
      const response = await Endpoint.BinList.available()
      dispatch({ type: 'updateBinList', payload: response.data })
    };
    fetchData();
  }, []);


  const trimData = (values, isEdit) => {
    const output = Object.assign({}, values)

    // removes
    delete output.intakeDate
    delete output.termsOfServiceLengthId
    delete output.ReferralInfoId
    delete output.referralOutCodeId
    delete output.Notes // the note should be there

    // adds
    if(!isEdit){
      output.TermsOfServiceLengthId = parseInt(values.termsOfServiceLengthId, 10)
      output.DateTime = values.intakeDate
      output.IntakeReferral = values.ReferralInfoId?.map(item => ({ ReferralInfoId: item }))
      output.IntakeReferralOut = values?.referralOutCodeId?.map(item => ({ ReferralOutCodeId: item }))
      output.MPA = values.mpa === 'yes'
    }
    return output
  }

  const reload = () => {
    props.onHide();
    window.location.reload();
  }

  const handleSubmit = (values) => {
    const editValues = Object.assign({}, trimData(values, false), {ClientId: parseInt(id, 10)})
    Endpoint.Clients.reEnroll(editValues).then(res => {
      if(values.Notes) {
        const notesPayload = [{
          "NoteTypeId": 1,
          "Value": values.Notes,
          "ListAction": 1
        }]
        Endpoint.Clients.Notes.new({id:id, data: notesPayload}).then(() => reload()).catch(() => reload())
      } else {
        reload();
      }
    }).catch(err => {
      Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
    })

  }

  return (
      <>
        <Modal { ...props }
               aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
              initialValues={{
                termsOfServiceLengthId: id ? `${currentUser.termsOfServiceLengthId}` : Object.keys(TermsOfServiceLength)[0],
                binId: "",
                intakeDate: new Date().toISOString(),
              }}


              onSubmit={handleSubmit}
          >
            { ({  values, handleChange, isSubmitting, setFieldValue}) => (
                <Form>
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body className="show-grid">
                    <Container>
                      <Row>
                        <Col xs={6} md={6}>
                          <div className="field_element">
                            <label className="field_element_label">Select a Bin<span className="text-danger"></span></label>
                            <Field name="binId" component="select" className="form-control" tabIndex="10" required>

                              { state.binList.length === 0 ?
                                  <option value="">No Bin Is Available</option>:
                                  <option value="">Select a Value</option>
                              }

                              {state.binList &&
                              state.binList.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.binNumber}
                                  </option>
                              ))}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={6} md={6}>
                          <div className="field_element">
                            <label htmlFor="intakeDate" className="field_element_label">Intake Date</label>

                            <MuiThemeProvider theme={customPickerTheme}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} customTheme>
                                <DateTimePicker
                                    name="intakeDate"
                                    InputProps={{ className:"form-control", disableUnderline: true }}
                                    value={values.intakeDate}
                                    format="dd-MM-yyyy hh:mm"
                                    onChange={value => setFieldValue("intakeDate", value)}
                                />
                              </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} md={6}>
                          <label className="field_element_label">Term of Service Agreement Length</label>
                          <div className="radio_div">
                            {Object.keys(TermsOfServiceLength).map((tosKey, i) => (
                                <label key={tosKey} className="radio_label field_element_label">
                                  <Field
                                      name="termsOfServiceLengthId"
                                      component="input"
                                      type="radio"
                                      value={tosKey}
                                      style={{ margin: 0 }}
                                      tabIndex={17 + i}
                                      required
                                  />{" "}
                                  { TermsOfServiceLength[tosKey]} Days
                                </label>
                            ))}
                          </div>
                        </Col>
                        <Col xs={6} md={6}>
                          <div className="field_element">
                            <label htmlFor="clarityId" className="field_element_label">Referral</label>
                            <Select
                                className="referral-select-component"
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={(data) => handleChange({target: {
                                    name: "ReferralInfoId",
                                    value: data?.map(item => item.value)
                                  }})}
                                defaultValue={values?.ReferralInfoId?.map(value => ({
                                  value,
                                  label: ReferralInfo[value]?.replaceAll("_", " ")
                                }))}
                                options={Object.keys(ReferralInfo).map(key => ({
                                  label:  ReferralInfo[key]?.replaceAll("_", " "),
                                  value: parseInt(key)
                                }))}
                                required
                            />
                          </div>
                          <div className="field_element">
                            <label htmlFor="referralOutCodeId" className="field_element_label">Referral Out Code</label>
                            <Select
                                className="referral-select-component"
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={(data) => handleChange({target: {
                                    name: "referralOutCodeId",
                                    value: data?.map(item => item.value)
                                  }})}
                                defaultValue={values?.referralOutCodeId?.map(value => ({
                                  value,
                                  label: ReferralOutCode ? ReferralOutCode[value]?.replace(/_/g, " ") : ""
                                }))}
                                options={Object.keys(ReferralOutCode||{}).map(key => ({
                                  label:  ReferralOutCode[key]?.replace(/_/g, " "),
                                  value: parseInt(key)
                                }))}
                                required
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <div className="field_element">
                            <label htmlFor="Notes" className="field_element_label">Notes</label>
                            <Field
                                name="Notes"
                                component="textarea"
                                className="form-control add_client__notes"
                                tabIndex="20"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button type="submit" disabled={isSubmitting}>Enroll</Button>
                  </Modal.Footer>

                </Form>
            )}
          </Formik>
        </Modal>

      </>
  )
}

export default EnrolPopUp