import React, { useReducer } from "react"
import { Form, Row, Col } from "react-bootstrap"
import {isDateValid} from "./filter";
import {COMPARATOR} from "./";

const initialState = {operator: "", date: ""}
function reducer (state, action)  {
    switch(action.type) {
        case 'setOperator':
            return {...state, operator: action.payload}
        case 'setDate':
            return {...state, date: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export const  DateFilter = props => {
    const { item = {}, handleChange = () => {} } = props
    const [state, dispatch] = useReducer(reducer, initialState, undefined)

    const handleSelectDate = event => {
        const { value } = event?.target

        if(isDateValid(value) && state.operator){
            dispatch({ type: 'setDate', payload: new Date(value)?.toISOString() })
            handleChange(item)({target: {value: [new Date(value)?.toISOString(), state.operator]}})
        } else {
            dispatch({ type: 'setDate', payload: new Date()?.toISOString() })
            handleChange(item)({target: {value: null}})
        }
    }

    const handleSelectOperator = event => {
        const { value } = event?.target;
        dispatch({ type: 'setOperator', payload: value })

        if(isDateValid(state.date) && value){
            handleChange(item)({target: {value: [state.date, value]}})
        } else {
            handleChange(item)({target: {value: null}})
        }
    }

    return (
        <Row className="m-0 flex-nowrap">
            <Col xs={3} className="p-0">
                <Form.Control onChange={handleSelectOperator} className="date-filter-select" size="sm" as="select">
                    <option value=""/>
                    {Object.keys(COMPARATOR).map(key => (
                        <option key={key} value={COMPARATOR[key]}>{COMPARATOR[key]}</option>
                    ))}
                </Form.Control>
            </Col>
            <Col xs={9} className="p-0">
                <input onChange={handleSelectDate} ref={item.ref} className="form-control form-control-sm w-100 date-filter-input" type="date"/>
            </Col>
        </Row>
    )
}