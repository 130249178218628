import React, { useMemo, useRef, useReducer } from "react";
import { withRouter } from "react-router-dom";
import { formatToLocal } from '../helpers/dateHelpers'
import { useOptionsState } from '../helpers/optionsContext'
import FaceDetection from '../components/FaceDetection'
import { Row, Button, Col } from "react-bootstrap";
import { Table, DATA_TYPES } from "./Table"
import  ImageSearch from "./ImageSearch"

function reducer (state, action)  {
  switch(action.type) {
    case 'setIsActive':
      return {...state, isActive: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

const ClientList = ({ list, handleSelect }) => {
  const [state, dispatch] = useReducer(reducer, {clientsData: list, isActive: true}, undefined)
  const { AccountStatus } = useOptionsState()
  const childRef = useRef()
  const fullName = useRef()
  const binNumber = useRef()
  const expirationDate = useRef()
  const latestCheckinDate = useRef()
  const accStatus = useRef()
  const isBinEmpty = useRef()

  const setActive = () => {
    dispatch({ type: 'setIsActive', payload: true })
    childRef.current.resetFilters()
    clearAll()
  };
  const setDeActive = () => {
    dispatch({ type: 'setIsActive', payload: false })
    childRef.current.resetFilters()
    clearAll()
  };

  const clearAll = () => {
    if(fullName.current) fullName.current.value = ""
    if(binNumber.current) binNumber.current.value = ""
    if(expirationDate.current) expirationDate.current.value = ""
    if(latestCheckinDate.current) latestCheckinDate.current.value = ""
    if(accStatus.current) accStatus.current.value = ""
    if(isBinEmpty.current) isBinEmpty.current.value = ""
  };
  
  // const updateClientsData = partialData =>{
  //   const allData = clientsData.slice(0)
  //   const clientIdx = allData.findIndex(item=>item.id===partialData.id)
  //   if(clientIdx !== -1){
  //     const newData = Object.assign({}, allData[clientIdx], partialData)
  //     allData[clientIdx] = newData
  //     setClientsData(allData)
  //   }
  // }

  const { clients, schema } = useMemo(()=>{
    let clientFilterd = [];

    if(state.isActive){
      clientFilterd = state.clientsData.filter(item => item.accountStatusId === 1)
    } else {
      clientFilterd = state.clientsData
    }
    
    const clients = clientFilterd?.map(item => {
      return {
        ...item,
        id: item.clientId,
        fullName: `${item.firstName} ${item.lastname}`,
        status: item.accountStatusId,
      }
    })

    const schema = [
      {
        label: "Name",
        name: "fullName",
        type: DATA_TYPES.STRING,
        ref: fullName,
        formatter: (data) => <p className="text-left w-100 m-0">{data.fullName}</p>
      },{
        label: "Bin Number",
        name: "binNumber",
        type: DATA_TYPES.STRING,
        ref: binNumber,
      },{
        label: "Last Checkin",
        name: "latestCheckinDate",
        type: DATA_TYPES.DATE,
        ref: latestCheckinDate,
        formatter: (data) => <p className="text-left w-100 m-0">{formatToLocal(data.latestCheckinDate)}</p>,
      },{
        label: "Expiration Date",
        name: "expirationDate",
        type: DATA_TYPES.DATE,
        ref: expirationDate,
        formatter: (data) => <p className="text-left w-100 m-0">{formatToLocal(data.expirationDate)}</p>,
      },{
        label: "Status",
        name: "status",
        type: DATA_TYPES.OPTION,
        ref: accStatus,
        options: Object.keys(AccountStatus)?.map(key => ({
          value: key,
          label: AccountStatus[key].replace(/_/g, " ")
        })),
        formatter: (data) => <p className={`w-100 m-0 ${data.status === 1 ? "text-success" : null}`}>{AccountStatus[data.status].replace(/_/g, " ")}</p>,
      }
    ];

    if(state.isActive){
      schema.pop();
      schema.push({
        label: "Empty Bin",
        name: "isBinEmpty",
        type: DATA_TYPES.OPTION,
        options: [
          {value: true, label: "Yes"},
          {value: false, label: "No"},
        ],
        ref: isBinEmpty,
        formatter: (data) => data.isBinEmpty ? "Yes" : "No"
      })
    }

    return { clients, schema }

  }, [state.clientsData, state.isActive, AccountStatus])

  return (
    <div>
      <Row className="justify-content-between align-items-center py-2 m-0">
        <Col xs="auto p-0">

          <Button
            size="sm"
            onClick={() => setActive()}
            variant={`text-dark btn-borderless ${state.isActive && "btn-selected" } font-weight-bold mr-1`}
          >
          Active Clients
          </Button>

          <Button
            size="sm"
            onClick={() => setDeActive()}
            variant={`text-dark btn-borderless ${!state.isActive && "btn-selected"} font-weight-bold`}
          >
          All Clients
          </Button>
        
        </Col>
        <Col xs="auto" className="row m-0 flex-nowrap align-items-center p-0">
          <Col className="px-1">
            <ImageSearch/>
          </Col>
          <FaceDetection />
        </Col>
      </Row>
      <Table
        onSelect={handleSelect}
        list={clients}
        uniqeKeyName="id"
        schema={schema}
        ref={childRef}
      />
        
    </div>
  );
}

export default withRouter(ClientList);