
import React, { useReducer } from "react";
import { Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { Formik, Field } from "formik";
import { Endpoint } from '../../api/base'
import { useOptionsState } from '../../helpers/optionsContext'
import NotesList from '../NotesList'
import Swal from "sweetalert2";
import {MuiThemeProvider} from "@material-ui/core";
import {customPickerTheme} from "../customPickerTheme";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import MoveToGrayBin from "./MoveToGrayBin";

function reducer (state, action)  {
    switch(action.type) {
        case 'setShow':
            return {...state, show: action.payload}
        case 'setLoading':
            return {...state, loading: action.payload}
        case 'setShowMoveToGrayBin':
            return {...state, showMoveToGrayBin: action.payload}
        case 'setValues':
            return {...state, values: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export default function ClientCloseOut(props) {
    const { size = "sm", className, clientId } = props
    const { CloseOutReasonType, ReferralOutCode, AccountStatus } = useOptionsState()
    const [state, dispatch] = useReducer(reducer, {show: false, loading: false, showMoveToGrayBin: false, values: undefined}, undefined)

    const handleClose = () => dispatch({ type: 'setShow', payload: false })
    const handleShow = () => dispatch({ type: 'setShow', payload: true })

    const handleShowMoveToGrayBin = (values) => {
        dispatch({ type: 'setValues', payload: values });
        dispatch({ type: 'setShowMoveToGrayBin', payload: true });
    }

    const handleCloseMoveToGrayBin = (isSubmit = false) =>{
        dispatch({ type: 'setShowMoveToGrayBin', payload: false });
        if(isSubmit) handleSubmit(state.values);
    }

    const reload = () => {
        handleClose();
        window.location.reload();
    }

    const handleSubmit = (values)=>{
        dispatch({ type: 'setLoading', payload: true });
        const formatReasons = (vals) => {
            let closeoutReasonArr = []
            vals.reason.forEach( reason => {
                let closeoutReason = {}
                if(values.OtherReason){
                    closeoutReason.otherReason = values.OtherReason
                    closeoutReason.CloseOutReasonTypeId = reason
                }
                else {
                    closeoutReason.CloseOutReasonTypeId = reason
                }
                closeoutReasonArr.push(closeoutReason)
            })
            return closeoutReasonArr
        }

        const formatCodes = vals => {
            const referralCode = []
            vals.referral.forEach( ref => {
                referralCode.push({ ReferralOutCodeId: ref })
            })
            return referralCode
        }

        const payload = {
            AccountStatusId: values.status,
            ClientId: clientId,
            Reason: values.parent,
            CloseOutReasons: [...formatReasons(values)],
            CloseOutReferralCode: [...formatCodes(values)],
            Notes: values.Notes,
            DateTime: values.closeOutDate
        }
        console.log(payload.CloseOutReferralCode)

        Endpoint.Clients.CloseOut.update(payload).then(res=>{
            if(values.Notes) {
                const notesPayload = [{
                    "NoteTypeId": 1,
                    "Value": values.Notes,
                    "ListAction": 1
                }]
                Endpoint.Clients.Notes.new({id:clientId, data: notesPayload}).then(() => reload()).catch(() => reload())
            } else {
                reload();
            }
        }).catch((err)=>{
            //operation failed. please try again!
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
            handleClose()
        })
    }

    return (
        <>
            {props.button?
                <Button className={className} {...props.button} size={size} variant="outline-danger" onClick={handleShow}>Closing Out</Button>
                :
                <Button className={className} size={size} variant="outline-danger" onClick={handleShow}>
                    Closing Out
                </Button>
            }

            <MoveToGrayBin show={state.showMoveToGrayBin} handleClose={handleCloseMoveToGrayBin} inventory={props.inventory} binId={props.binId} clientId={clientId}/>

            <Modal show={state.show} size="lg" onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Client Closing Out </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        onSubmit={handleShowMoveToGrayBin}
                        initialValues={{
                            status: Object.keys(AccountStatus)[1],
                            reason: [],
                            referral: [],
                            extra: undefined,
                            closeOutDate: new Date().toISOString()
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="status">
                                            <Form.Label>Client Status</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={values.status}
                                                onChange={handleChange}
                                                isValid={touched.status && !errors.parent}
                                            >
                                                {Object.keys(AccountStatus).slice(1).map((status, idx) =>
                                                    <option key={idx} value={status} label={AccountStatus[status].replaceAll("_", " ")} />
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {values.status !== '1'?
                                        <Col>
                                            <div className="field_element">
                                                <label htmlFor="closeOutDate" className="field_element_label">Close Out Date</label>
                                                <MuiThemeProvider theme={customPickerTheme}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} customTheme>
                                                        <DateTimePicker
                                                            name="closeOutDate"
                                                            InputProps={{ className:"form-control", disableUnderline: true }}
                                                            value={values.closeOutDate}
                                                            format="dd-MM-yyyy hh:mm"
                                                            onChange={value => setFieldValue("closeOutDate", value)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </MuiThemeProvider>
                                            </div>
                                        </Col>
                                        :null}
                                </Row>

                                <Row id="checkbox-group"><h4 style={{ padding: '1rem' }}>Closing Out Reason</h4></Row>
                                <Select
                                    className="referral-select-component"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={(data) =>
                                        handleChange({target: {
                                            name: "reason",
                                            value: data?.map(item => item.value)
                                        }}
                                    )}
                                    options={Object.keys(CloseOutReasonType||{}).map(key => ({
                                        label:  CloseOutReasonType[key]?.replace(/_/g, " "),
                                        value: parseInt(key)
                                    }))}
                                />


                                {values.reason.includes(11) || values.reason.includes("11") ?
                                    <Form.Group controlId="OtherReason">
                                        <Form.Control type="text" value={values.extra} onChange={handleChange} placeholder="why?" />
                                        <Form.Text className="text-muted">
                                            Please Explain the reason
                                        </Form.Text>
                                    </Form.Group>
                                    :null}

                                <Row id="checkbox-group"><h4 style={{ padding: '1rem' }}>Referred Out Categories</h4></Row>
                                <Row role="group" aria-labelledby="checkbox-group">
                                    { Object.keys(ReferralOutCode).map((mainRes,idx) => (
                                        <Col xs={12} md={6}>
                                            <label key={`referral_${idx}`}>
                                                <Field type="checkbox" style={{ marginRight: '5px' }} name="referral" value={mainRes} />
                                                { ReferralOutCode[mainRes].replaceAll("_", " ") }
                                            </label>
                                        </Col>
                                    )) }
                                </Row>

                                {values.reason === "Other"?
                                    <Form.Group controlId="extra">
                                        <Form.Control type="text" value={values.extra} onChange={handleChange} placeholder="why?" />
                                        <Form.Text className="text-muted">
                                            Please Explain the reason
                                        </Form.Text>
                                    </Form.Group>
                                    :null}
                                <NotesList title="Notes" noteTypeId={1} clientId={clientId} />

                                <div className="field_element">
                                    <Field
                                        name="Notes"
                                        component="textarea"
                                        className="form-control add_client__notes"
                                        tabIndex="20"
                                    />
                                </div>
                                { values.status !== '1' ?
                                    <Button type="submit" variant="outline-danger w-100" disabled={state.loading}>Confirm Closing Out</Button>
                                    : null }
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}


