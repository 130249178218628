import React, {useEffect, useReducer} from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import {baseURL, Endpoint} from "../api/base";
import Select from 'react-select';
import { withRouter, useParams } from "react-router-dom";
import { CameraModal } from "../components/CameraModal"
import { Button, Modal, Row, Alert, Spinner, Col } from 'react-bootstrap';
import { useOptionsState } from '../helpers/optionsContext';
import { InventoryList } from '../components/InventoryList';
import Swal from 'sweetalert2';
import FormikErrorFocus from 'formik-error-focus'
import {string, object, date, array} from "yup";
import MaskedInput from "react-text-mask";
import {DateTimePicker, DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {MuiThemeProvider} from '@material-ui/core'
import {customPickerTheme} from "../components/customPickerTheme";

// css imports
import 'react-html5-camera-photo/build/css/index.css';
import "./AddClientPage.css";
import NotesList from "../components/NotesList";
import {ConfirmModal} from "../components/WaitList/ConfirmModal";



const initialState = {
  showModal: false,
  user: null, loading: false,
  binList: [],
  oldValues: undefined,
  notes: undefined
}

function reducer (state, action)  {
  switch(action.type) {
    case 'setShowModal':
      return {...state, showModal: action.payload}
    case 'setUser':
      return {...state, user: action.payload}
    case 'setLoading':
      return {...state, loading: action.payload}
    case 'setBinList':
      return {...state, binList: action.payload}
    case 'setOldValues':
      return {...state, oldValues: action.payload}
    case 'setNotes':
      return {...state, notes: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

export function AddClientPage(props) {
  const { InventoryItemType, TermsOfServiceLength, ReferralInfo, ReferralOutCode, Race, Location, Ethnicity } = useOptionsState()
  let { id, binid, waitlistId } = useParams()
  const [state, dispatch] = useReducer(reducer, initialState, undefined)

  const handleCloseModal = () => dispatch({ type: 'setShowModal', payload: false })

  function dataURLtoBlob(dataURL) {
    var byteString = atob(dataURL.split(",")[1]);
    var mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await Endpoint.BinList.available();
      dispatch({ type: 'setBinList', payload: response.data })
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (id) {
      Endpoint.Clients.Notes.get(id).then(res => {
        dispatch({ type: 'setNotes', payload: res.data?.filter(item => (item.noteTypeId === 1)) })
      }).catch(e => {

      })
    }
  }, [id]);

  const setNotes = (notes) => {
    dispatch({ type: 'setNotes', payload: notes })
  }

  useEffect(() => {
    console.log(`Here we are ${waitlistId}`)
    if(waitlistId){
      // Fetch waitlist data
      dispatch({ type: 'setLoading', payload: true })
      const fetchData = async () => {
        const response = await Endpoint.WaitList.get(waitlistId);
        dispatch({ type: 'setLoading', payload: false })
        let waitListData = response.data
        dispatch({ type: 'setUser', payload: {
            ...waitListData,
            phoneNumber: waitListData.contactNumber,
            emailAddress: waitListData.email,
          } })
      };
      fetchData();
    }

  }, [waitlistId]);

  useEffect(() => {
    if (id) {
      Endpoint.Clients.get(id).then(result => {
        Endpoint.Clients.getImage(id).then(res => {
          setUser(result, URL.createObjectURL(res.data));
        }).catch(e => {
          setUser(result, `${baseURL}client/DownloadImage/${id}`);
        })
      }).catch(err => {
        //Failed to catch user profile
        Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
        console.error(err)
      })
    }
  }, [id]);

  const setUser = (result, imageSrc) => {
    dispatch({ type: 'setUser', payload: {
        ...result.data,
        imageSrc: imageSrc,
        showWebcam: false,
      }})
  }

  const trimData = (values, isEdit) => {
    const output = Object.assign({}, values)
    // removes
    delete output.addInventory
    delete output.hasNewPhoto
    delete output.showWebcam
    delete output.imageSrc
    delete output.termsOfServiceLengthId
    delete output.referralInfoId
    delete output.referralOutCodeId
    delete output.mpa
    delete output.notes // the note should not be there
    delete output.Notes // the note should not be there
    values.addInventory === 'false' && delete output.inventory
    delete output.inventory

    // adds
    output.ethnicityId = parseInt(values.ethnicityId, 10)
    output.raceId = parseInt(values.raceId, 10)
    output.locationId = parseInt(values.locationId, 10)
    output.Intake = [
      {
        clientId: id,
        id: state.user?.intake?.slice(-1)?.[0]?.id ,
        termsOfServiceLengthId: parseInt(values.termsOfServiceLengthId, 10),
        dateTime: values.intakeDate,
        IntakeReferral: values?.referralInfoId?.map(item => ({ ReferralInfoId: item })),
        IntakeReferralOut: values?.referralOutCodeId?.map(item => ({ referralOutCodeId: item })),
        binId: values.binId,
        mpa: values.mpa === 'yes'
      }
    ]
    return output
  }

  const handleDelete = (handleChange) => {
    dispatch({ type: 'setLoading', payload: true });
    handleChange({ target: { name: "showDelete", value: false } });
    Endpoint.Clients.delete(id).then(() => {
      dispatch({ type: 'setLoading', payload: false });
      props.history.push(`/app/clients`);
    }).catch(err => {
      //failed to delete!
      Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error');
      dispatch({ type: 'setLoading', payload: false });
    })
  }

  const submitNote = (id, notes) => {
    Endpoint.Clients.Notes.new({
      id: id,
      data: [{
        "NoteTypeId":1,
        "Value":notes,
        "ListAction":1
      }]}).then(res => {
      dispatch({ type: 'setLoading', payload: false })
    }).catch(err => {
      //There was erorr adding note
      Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
    })
  }

  const handleSubmit = async (values) => {
    dispatch({ type: 'setOldValues', payload: values })
    const notes = values.Notes;
    const addValues =  trimData(values)
    if(!id){
      if(!values || !values.imageSrc){
        Swal.fire('Client Photos are Required', "Please Upload or Take a Photo of the Client", 'warning')
        return
      }
      dispatch({ type: 'setLoading', payload: true })
      try {
        const res = await Endpoint.Clients.new(addValues)

        // Add Inventory
        if (values.addInventory === "true") {
          handleInventory(res.data.id, values.inventory)
        }
        if(values.addInventory === "false" && !values?.imageSrc){
          console.log(values.addInventory)
          props.history.push(`/app/clients/${res?.data?.id}`);
        }

        if(notes){
          submitNote(res.data.id, values.Notes);
        }

        // If user was on waitlist, let's delete them from there
        if(waitlistId){
          Endpoint.WaitList.delete(waitlistId).then(res => {
          }).catch(err => {
            //Coul not delete user from waitlist, you may manually delete them
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
            console.error(err)
          })
        }
        if(values?.imageSrc){
          handleUplaodPhoto(res.data.id, values.imageSrc, true, true)
        }

      } catch (err) {
        dispatch({ type: 'setLoading', payload: false })
        Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
      }
        
    } else {
      if(notes && typeof notes == "string"){
        submitNote(id, values.Notes);
      }

      const editValues = Object.assign({}, trimData(values, true), {id: parseInt(id, 10)})
      dispatch({ type: 'setLoading', payload: true })
      Endpoint.Clients.update(editValues).then(res => {
        handleUplaodPhoto(id, values?.imageSrc, true, values.hasNewPhoto)
      }).catch(err => {
        dispatch({ type: 'setLoading', payload: false })
        Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
      })
    }
  }

  const handleInventory = (clientId, inventory)=>{
    console.log(inventory)
    dispatch({ type: 'setLoading', payload: true })
    const inventoryPayload = {
      "ClientId": clientId,
      "IsEmptyBin": !!inventory.length,
      "InventoryListDto": inventory
    }
    Endpoint.Inventory.update(inventoryPayload).then(res => {
      dispatch({ type: 'setLoading', payload: false })
      props.history.push(`/app/clients/${clientId}`);
    }).catch((err) => {
      dispatch({ type: 'setLoading', payload: false })
      props.history.push(`/app/clients/${clientId}`);
      //Failed to update inventory
      Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
      console.error(err)
    });

  }

  const handleUplaodPhoto = (id, img, redirect, hasNewPhoto) =>  {
    if (hasNewPhoto){
      const formData = new FormData();
      let imgBlob = null;

      try {
        imgBlob = dataURLtoBlob(img)
      } catch {
        console.error("failed to convert image to blob file")
      }

      formData.append("img", imgBlob);

      //HttpPost(`client/UploadImage/${id}`, formData).then(res => {
      Endpoint.Clients.UploadImage({id: id, data: formData}).then(res => {
        dispatch({ type: 'setLoading', payload: false })
        if(res.data === `Image uploaded, but failed to add face to face recognition` || res.status === 400) {
          Swal.fire('something went wrong', "A Face wasn't recognised in the image, Please try again", 'error')
          return false;
        } else {
          redirect && props.history.push(`/app/clients/${id}`);
          return true;
        }
      }).catch((err) => {
        dispatch({ type: 'setLoading', payload: false })
        //Failed to update image
        Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
        return false;
      });
    } else {
      redirect && props.history.push(`/app/clients/${id}`);
      return false;
    }
  }

  const SignUpForm = () => (
      <Formik
          initialValues={{
            locationId: id ? `${state.user.locationId}` : state.oldValues?.locationId || Object.keys(Location)[0],
            termsOfServiceLengthId: (id && state.user.intake) ? `${state.user.intake[state.user.intake.length - 1].termsOfServiceLength.id}` : state.oldValues?.termsOfServiceLengthId || Object.keys(TermsOfServiceLength)[0],
            binId: id ? state.user.binId : state.oldValues?.binId || binid ? binid : state.binList && state.binList.length && state.binList[0].id,
            firstName: (id || (waitlistId && state.user) ) ? state.user.firstName : state.oldValues?.firstName || "",
            lastName: (id || (waitlistId && state.user) ) ? state.user.lastName : state.oldValues?.lastName || "",
            dateOfBirth: id ? state.user.dateOfBirth.split("T")[0] : state.oldValues?.dateOfBirth || new Date("1-1-2004"),
            clarityId: id ? state.user.clarityId : state.oldValues?.clarityId || "",
            ethnicityId: id ? `${state.user.ethnicityId}` : state.oldValues?.ethnicityId || "",
            raceId: id ? `${state.user.raceId}` : state.oldValues?.raceId || "",
            referralInfoId: id ? state.user?.intake?.slice(-1)?.[0]?.intakeReferral?.map(item => item.referralInfoId) : state.oldValues?.referralInfoId || undefined,
            referralOutCodeId: id ? state.user?.intake?.slice(-1)?.[0]?.intakeReferralOut?.map(item => item.referralOutCodeId) : state.oldValues?.referralOutCodeId || undefined,
            gender: id ? state.user.gender : state.oldValues?.gender || "",
            phoneNumber: (id || (waitlistId && state.user) ) ? state.user.phoneNumber : state.oldValues?.phoneNumber || "",
            emailAddress: (id || (waitlistId && state.user) ) ? state.user.emailAddress : state.oldValues?.emailAddress || "",
            veteran: id ? state.user.veteran : state.oldValues?.veteran || false,
            Notes: (id || (waitlistId && state.user) ) ? state.user.notes : state.oldValues?.Notes || "",
            emergencyContacts: id ? state.user.emergencyContacts : state.oldValues?.emergencyContacts || [
              {
                fistName: "",
                lastName: "",
                phoneNumber: "",
                emailAddress: "",
              }
            ],
            intakeDate: id ? state.user?.intake?.slice(-1)?.[0]?.dateTime+'Z' : state.oldValues?.intakeDate ? state.oldValues?.intakeDate : new Date().toISOString(),
            imageSrc: id ? state.user.imageSrc : state.oldValues?.imageSrc || "",
            showWebcam: false,
            hasNewPhoto: false,
            showDelete: false,
            showSubmitConfirm: false,
            addInventory: state.oldValues?.addInventory || "false",
            mpa: id ? (state.user?.intake?.slice(-1)?.[0]?.mpa ? "yes" : "no") : state.oldValues?.mpa || "no",
            inventory: state.oldValues?.inventory || [
              {
                isNew: true,
                description: "",
                InventoryItemTypeId: Object.keys(InventoryItemType)[0],
                quantity: 1,
                ListAction: 1,
              }
            ]
          }}
          validationSchema={object().shape({
            firstName: string().required("Required."),
            lastName: string().required("Required."),
            emailAddress: string().notRequired().email("Email is not valid, Please enter a correct format"),
            gender: string().required("Required."),
            raceId: string().required("Required."),
            ethnicityId: string().required("Required."),
            dateOfBirth: date().max(new Date(new Date().setFullYear(new Date().getFullYear() - 17)), "Should be at least 17 years old!").required("Required."),
            clarityId: string().required("Required."),
            intakeDate: date().max(new Date(new Date().setHours(24,59,59,59)), "The intake date cannot be greater than today's date"),
            phoneNumber: string().notRequired().matches(/^[(]*[0-9]+[) ]*[0-9]+[-]*[0-9]+$/, 'Phone number is wrong!'),
            emergencyContacts: array()
                .of(object().shape({
                  phoneNumber: string().matches(/^[(]*[0-9]+[) ]*[0-9]+[-]*[0-9]+$/, 'Phone number is wrong!')
                }))
          })}
          validateOnBlur={false}
          onSubmit={handleSubmit}
      >
        { ({ submitForm, errors, values, handleChange, setFieldValue }) => {
          return(
              <Form className="py-5" >
                <Row className="m-0">
                  {state.loading &&
                  <div className="add_client__loading">
                    <Spinner animation="border" variant="primary" />
                  </div>
                  }
                  <Col xs={12} xl={4} className="px-4">
                    <div className="col-lg-12">
                      <CameraModal isOpen={values.showWebcam} handleChange={handleChange} />
                      <TakenPicture hasNewPhoto={values.hasNewPhoto} imageSrc={values.imageSrc} handleChange={handleChange} />
                      <ConfirmModal
                          loading={state.loading}
                          text="Are you sure you want to delete this profile?"
                          isOpen={values.showDelete}
                          handleClose={() => handleChange({ target: { name: "showDelete", value: false } })}
                          onConfirm={() => handleDelete(handleChange)}
                      />
                      <ConfirmModal
                          loading={state.loading}
                          text="Are you sure you want to submit this profile?"
                          submitText="Submit"
                          submitVariant="success"
                          isOpen={values.showSubmitConfirm}
                          handleClose={() => handleChange({ target: { name: "showSubmitConfirm", value: false } })}
                          onConfirm={() => {
                            handleChange({ target: { name: "showSubmitConfirm", value: false } });
                            submitForm();
                          }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={4} className="px-4">
                    <h3>{id ? "Edit Client Profile" : (waitlistId ? "Waitlist client Intake" : "Client Intake")}</h3>
                    <div className="field_element">
                      <label htmlFor="firstName" className="field_element_label">
                        First Name<span className="text-danger"> *</span>
                      </label>
                      <Field
                          name="firstName"
                          component="input"
                          className="form-control"
                          placeholder="John"
                          type="text"
                          tabIndex="1"
                      />
                      {errors.firstName && <div className="validate_error">{errors.firstName}</div>}
                    </div>
                    <div className="field_element">
                      <label htmlFor="lastName" className="field_element_label">
                        Last Name<span className="text-danger"> *</span>
                      </label>
                      <Field
                          name="lastName"
                          component="input"
                          className="form-control"
                          placeholder="Doe"
                          type="text"
                          tabIndex="2"
                      />
                      {errors.lastName && <div className="validate_error">{errors.lastName}</div>}
                    </div>
                    <Row>
                      <Col xs={12} sm={6}>
                        <div className="field_element">
                          <label htmlFor="gender" className="field_element_label">
                            Gender<span className="text-danger"> *</span>
                          </label>
                          <Field name="gender" component="select" className="form-control" tabIndex="4">
                            <option value="">Select a Value</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">other</option>
                          </Field>
                          {errors.gender && <div className="validate_error">{errors.gender}</div>}
                        </div>
                      </Col>
                      <Col xs={12} sm={6}>
                        <div className="field_element">
                          <label
                              htmlFor="raceId"
                              className="field_element_label"
                          >
                            Race<span className="text-danger"> *</span>
                          </label>
                          <Field name="raceId" component="select" className="form-control" tabIndex="4">
                            <option value="">Select a Value</option>
                            {Object.keys(Race).map(raceKey => (
                                <option key={raceKey} value={raceKey}>
                                  {Race[raceKey].replace(/_/g, " ")}
                                </option>
                            ))}
                          </Field>
                          {errors.raceId && <div className="validate_error">{errors.raceId}</div>}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="field_element">
                          <label
                              htmlFor="ethnicityId"
                              className="field_element_label"
                          >
                            Ethnicity<span className="text-danger"> *</span>
                          </label>
                          <Field name="ethnicityId" component="select" className="form-control" tabIndex="4">
                            <option value="">Select a Value</option>
                            {Object.keys(Ethnicity).map(id => (
                                <option key={id} value={id}>
                                  {Ethnicity[id].replace(/_/g, " ")}
                                </option>
                            ))}
                          </Field>
                          {errors.ethnicityId && <div className="validate_error">{errors.ethnicityId}</div>}
                        </div>
                      </Col>
                    </Row>

                    <div className="field_element">
                      <label htmlFor="dateOfBirth" className="field_element_label">
                        Date Of Birth<span className="text-danger"> *</span>
                      </label>
                      {/* <Field
                          name="dateOfBirth"
                          component="input"
                          className="form-control"
                          placeholder="mm/dd/yyyy"
                          type="date"
                          tabIndex="5"
                      /> */}
                       <MuiThemeProvider theme={customPickerTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} customTheme>
                          <DatePicker
                              name="dateOfBirth"
                              InputProps={{ className:"form-control", disableUnderline: true }}
                              value={values.dateOfBirth}
                              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 17))}
                              maxDateMessage={"Should be at least 17 years old!"}
                              format="MM-dd-yyyy"
                              onChange={value => setFieldValue("dateOfBirth", value)}
                              tabIndex="6"
                          />
                          {errors.dateOfBirth && <div className="validate_error">{errors.dateOfBirth}</div>}                        
                          </MuiPickersUtilsProvider>
                      </MuiThemeProvider>
                    </div>
                    <div className="field_element">
                      <label htmlFor="intakeDate" className="field_element_label">Intake Date</label>
                      <MuiThemeProvider theme={customPickerTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} customTheme>
                          <DateTimePicker
                              name="intakeDate"
                              InputProps={{ className:"form-control", disableUnderline: true }}
                              value={values.intakeDate}
                              format="MM-dd-yyyy hh:mm"
                              onChange={value => setFieldValue("intakeDate", value)}
                              tabIndex="6"
                          />
                          {errors.intakeDate && <div className="validate_error">{errors.intakeDate}</div>}
                        </MuiPickersUtilsProvider>
                      </MuiThemeProvider>
                    </div>
                    <div className="field_element">
                      {/* This form should not be editable when in edit mode id != false */}
                      <label className="field_element_label">Bin Number<span className="text-danger"> *</span></label>
                      <Field name="binId" component="select" className="form-control" tabIndex="7" disabled={id}>
                        {id && state.user ?
                            <option value={state.user.binId}>{state.user?.bin?.binNumber} (Current Bin) </option> :
                            null
                        }

                        { !id && state.binList.length === 0 ?
                            <option value="">No Bin Is Available</option>:
                            <option value="">Not Selected</option>
                        }

                        {!id && state.binList &&
                        state.binList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.binNumber}
                            </option>
                        ))}
                      </Field>
                    </div>
                    <label className="field_element_label">Term of Service Agreement Length</label>
                    <div className="radio_div">
                      {Object.keys(TermsOfServiceLength).map((tosKey, i) => (
                          <label key={tosKey} className="radio_label field_element_label">
                            <Field
                                name="termsOfServiceLengthId"
                                component="input"
                                type="radio"
                                value={tosKey}
                                style={{ margin: 0 }}
                                selected={true}
                                tabIndex={16 + i}
                            />{" "}
                            { TermsOfServiceLength[tosKey]} Days
                          </label>
                      ))}
                    </div>
                    {/* Note will be hidden in edit mode */}
                    {(!id || !state.notes?.length) && (
                        <div className="field_element">
                          <label htmlFor="Notes" className="field_element_label">Notes</label>
                          <Field
                              name="Notes"
                              component="textarea"
                              className="form-control add_client__notes"
                              tabIndex="20"
                          />
                        </div>
                    )}

                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6} xl={4} className="px-4">
                    <h3>&nbsp;</h3>

                    <div className="field_element">
                      <label htmlFor="phoneNumber" className="field_element_label">Phone Number</label>
                      <Field
                          name="phoneNumber"
                          component={FieldMask}
                          className="form-control"
                          placeholder="(949) 123-4567"
                          value={values.phoneNumber}
                          mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                          onChange={e => {
                            const value = e.target.value || '';
                            setFieldValue('phoneNumber', value);
                          }}
                          tabIndex="8"
                      />
                      {errors.phoneNumber && <div className="validate_error">{errors.phoneNumber}</div>}
                    </div>

                    <div className="field_element">
                      <label htmlFor="emailAddress" className="field_element_label">Email Address</label>
                      <Field
                          name="emailAddress"
                          className="form-control"
                          placeholder="john@yahoo.com"
                          type="email"
                          tabIndex="9"
                      />
                      {errors.emailAddress && <div className="validate_error">{errors.emailAddress}</div>}
                    </div>

                    <div className="field_element">
                      <label htmlFor="clarityId" className="field_element_label">Clarity Id<span className="text-danger"> *</span></label>
                      <Field
                          name="clarityId"
                          component="input"
                          className="form-control"
                          placeholder="123"
                          type="text"
                          tabIndex="10"
                      />
                      {errors.clarityId && <div className="validate_error">{errors.clarityId}</div>}
                    </div>

                    <div className="field_element">
                      <label htmlFor="clarityId" className="field_element_label">Referral In Code</label>
                      <Select
                          className="referral-select-component"
                          placeholder="Select a Value"
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(data) => handleChange({target: {
                              name: "referralInfoId",
                              value: data?.map(item => item.value)
                            }})}
                          defaultValue={values?.referralInfoId?.map(value => ({
                            value,
                            label: ReferralInfo[value]?.replace(/_/g, " ")
                          }))}
                          options={Object.keys(ReferralInfo).map(key => ({
                            label:  ReferralInfo[key]?.replace(/_/g, " "),
                            value: parseInt(key)
                          }))}
                          tabIndex="11"
                      />
                    </div>
                    <div className="field_element">
                      <label htmlFor="clarityId" className="field_element_label">Referral Out Code</label>
                      <Select
                          className="referral-select-component"
                          placeholder="Select a Value"
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(data) => handleChange({target: {
                              name: "referralOutCodeId",
                              value: data?.map(item => item.value)
                            }})}
                          defaultValue={values?.referralOutCodeId?.map(value => ({
                            value,
                            label: ReferralOutCode ? ReferralOutCode[value]?.replace(/_/g, " ") : ""
                          }))}
                          options={Object.keys(ReferralOutCode||{} ).map(key => ({
                            label:  ReferralOutCode[key]?.replace(/_/g, " "),
                            value: parseInt(key)
                          }))}
                          tabIndex="12"
                      />
                    </div>
                    <div className="field_element">
                      <label htmlFor="veteran" className="field_element_label">Veteran</label>
                      <Field
                          name="veteran"
                          id="veteran"
                          component="input"
                          type="checkbox"
                          tabIndex="13"
                          style={{ height: "20px", width: "20px" }}
                      />
                    </div>
                    <div className="field_element">
                      <label className="field_element_label">MPA</label>
                      <div className="radio_div">
                        <label className="radio_label field_element_label">
                          <Field
                              name="mpa"
                              component="input"
                              type="radio"
                              value="yes"
                              style={{ margin: 0 }}
                              tabIndex="14"
                          />{" "}
                          Yes
                        </label>
                        <label className="radio_label field_element_label">
                          <Field
                              name="mpa"
                              component="input"
                              type="radio"
                              value="no"
                              style={{ margin: 0 }}
                              tabIndex="15"
                          />{" "}
                          No
                        </label>
                      </div>
                    </div>

                    <Button variant="outline-danger" onClick={() => handleChange({ target: { name: "showDelete", value: true } })} size="sm">Delete Profile</Button>
                  </Col>
                </Row>
                {id && state.notes?.length ? (
                    <>
                      <hr className="mr-3"/>
                      <div className="row">
                        <NotesList setNotes={setNotes} title="Notes" noteTypeId={1} clientId={state.user.id} intake={true}/>
                      </div>
                    </>
                ) : ""}

                <hr className="mr-3"/>
                <FieldArray name="emergencyContacts">
                  { (inventoryActions) => (
                      <Col xs={12} sm={12} md={10} className="row m-0 p-2">

                        <Col xs={12} className="px-2 px-lg-1">
                          <h3>Emergency Contact</h3>
                        </Col>

                        <Row className="m-0 w-100 mb-2">
                          <Col xs={12} sm={6} md={6} lg={6} xl={2} className="px-1 d-none d-lg-flex pr-lg-1 py-2 ">
                            First Name
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={6} xl={2} className="px-1 d-none d-lg-flex py-2 ">
                            Last Name
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={6} xl={2} className="px-1 d-none d-lg-flex py-2 ">
                            Phone Number
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={6} xl={4} className="px-1 d-none d-lg-flex py-2 ">
                            Email
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={6} xl={2} className="px-1 py-2 ">

                            <Button
                                disabled={values?.emergencyContacts.length > 2}
                                size="sm w-100"
                                variant="outline-success btn-borderless text-success"
                                onClick={() => {
                                  inventoryActions.push({
                                    fistName: "",
                                    lastName: "",
                                    phoneNumber: "",
                                    emailAddress: "",
                                  })
                                  // setEmergencyContacts()

                                }}
                            >
                              Add Item
                            </Button>
                          </Col>
                        </Row>
                        {values?.emergencyContacts?.map((item, index) => (
                            <Row className="m-0 w-100" key={index}>
                              <Col xs={12} sm={6} md={6} lg={6} xl={2} className="px-1 pr-lg-1 py-2 ">
                                <Field
                                    name={`emergencyContacts.${index}.firstName`}
                                    component="input"
                                    className="form-control form-control-sm"
                                    placeholder="John"
                                    type="text"
                                    tabindex={21 + (index*4)}
                                />
                              </Col>

                              <Col xs={12} sm={6} md={6} lg={6} xl={2} className="px-1 py-2 ">
                                <Field
                                    name={`emergencyContacts.${index}.lastName`}
                                    component="input"
                                    className="form-control form-control-sm"
                                    placeholder="Doe"
                                    type="text"
                                    tabindex={22 + (index*4)}
                                />
                              </Col>

                              <Col xs={12} sm={6} md={6} lg={6} xl={2} className="px-1 py-2 ">

                                <Field
                                    placeholder="(949) 123-4567"
                                    className="form-control form-control-sm"
                                    component={FieldMask}
                                    value={item.phoneNumber}
                                    name={`emergencyContacts.${index}.phoneNumber`}
                                    mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                    onChange={e => {
                                      const value = e.target.value || '';
                                      setFieldValue(`emergencyContacts.${index}.phoneNumber`, value);
                                    }}
                                    tabindex={23 + (index*4)}/>
                                {errors.emergencyContacts && errors.emergencyContacts[index] && <div className="validate_error">{errors.emergencyContacts[index].phoneNumber}</div>}
                              </Col>

                              <Col xs={12} sm={6} md={6} lg={6} xl={4} className="px-1 py-2 ">
                                <Field
                                    name={`emergencyContacts.${index}.emailAddress`}
                                    className="form-control form-control-sm"
                                    placeholder="john@yahoo.com"
                                    type="email"
                                    tabindex={24 + (index*4)}
                                />
                              </Col>
                              <Col xs={12} sm={6} md={6} lg={6} xl={2} className="px-1 py-2 ">
                                <Button
                                    size="sm w-100"
                                    variant="outline-danger btn-borderless text-danger"
                                    onClick={() => inventoryActions.remove(index)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                        ))}

                      </Col>
                  )}
                </FieldArray>
                <hr className="mr-3"/>
                {!id &&
                <Row className="m-0">
                  <Col xs={12}>
                    <h3>Inventory</h3>
                  </Col>
                  <Row className="m-0 p-3" xs={12}>
                    <label
                        htmlFor="addInventory"
                        className="field_element_label"
                        style={{ margin: 0 }}
                    >
                      Add Inventory?
                    </label>
                    <div className="radio_div p-0 px-2">
                      <label className="radio_label field_element_label">
                        <Field
                            tabIndex="35"
                            name="addInventory"
                            component="input"
                            type="radio"
                            value="true"
                            style={{ margin: 0 }}
                        />{" "}
                        Yes
                      </label>
                      <label className="radio_label field_element_label">
                        <Field
                            name="addInventory"
                            component="input"
                            type="radio"
                            value="false"
                            style={{ margin: 0 }}
                        />{" "}
                        No
                      </label>
                    </div>
                  </Row>
                </Row>
                }

                <Col xs={12} lg={10}>
                  <InventoryList setFieldValue={setFieldValue} values={values}/>
                </Col>

                <Row className="m-0 px-3 justify-content-center justify-content-md-start ">
                  {state.user && state.user.accountStatusId && parseInt(state.user.accountStatusId) !== 1 ? null :
                      <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleChange({ target: { name: "showSubmitConfirm", value: true } })
                          }}
                          data-testid="submit"
                          className="btn btn-success "
                      >Submit</button>
                  }
                </Row>
                <FormikErrorFocus
                    offset={0}
                    align={'top'}
                    focusDelay={0}
                    ease={'linear'}
                    duration={0}
                />
              </Form>
          )}}

      </Formik>
  )

  const FieldMask = (props) => {

    return (
        <MaskedInput
            name="phoneNumber" {...props}/>
    );
  }


  const TakenPicture = (props) => {
    const { handleChange, imageSrc, hasNewPhoto } = props;

    const fileInput = React.createRef();

    const retake = (event) => {
      event.preventDefault();
      handleChange({ target: { name: "hasNewPhoto", value: false } });
      handleChange({ target: { name: "imageSrc", value: "" } });
    }

    const handleOpen = () => {
      handleChange({ target: { name: "showWebcam", value: true } });
    }

    const handleChangeFile = (event) => {
      event.preventDefault();
      const files = fileInput.current.files;

      if (FileReader && files && files.length) {
        handleChange({ target: { name: "hasNewPhoto", value: true } });
        var FR = new FileReader();

        FR.onload = function () {
          handleChange({ target: { name: "imageSrc", value: FR.result } });
          handleChange({ target: { name: "showWebcam", value: false } });
        }
        FR.readAsDataURL(files[0]);
      }
    }

    return (
        <Row className="flex-column align-items-center">

          <Col xs={12} className="camera-bottom-holder p-0">
            <img className="profile_image" alt="profile" src={imageSrc || "/images/camera.png"} />
          </Col>

          { hasNewPhoto &&
          <Col xs={12} className="camera-bottom-holder p-0">
            <Button variant="outline-danger" className="my-2 w-100" onClick={retake}>Retake</Button>
          </Col>
          }

          { !hasNewPhoto &&
          <Col xs={12} className="camera-bottom-holder p-0">
            <Button variant="dark" onClick={handleOpen} className="w-100 mt-2 mb-1">Take a Photo</Button>
            <Button variant="outline-dark btn-borderless text-dark" className="w-100 position-relative">
              Upload <input className="file-input position-absolute" id="file" name="file" type="file" onChange={handleChangeFile} ref={fileInput} />
            </Button>
          </Col>
          }
        </Row>
    )
  }

  const Waiting = () => (
      <Row className="justify-content-center add_page__loading">
        <Spinner animation="border" variant="primary" />
      </Row>
  )



  return (
      <>
        {/* { showWebcam ? <WebcamElement /> : showTakenPic ? <TakenPicture/> : showForm ? <SignUpForm/> : '' } */}
        {state.user && state.user.accountStatusId && parseInt(state.user.accountStatusId) !== 1 ? <Alert variant="warning">
          This is not an active client
        </Alert> : null}
        {id ? state.user ? <SignUpForm /> : <Waiting /> : <SignUpForm />}
        <Modal show={state.showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Attention</Modal.Title>
          </Modal.Header>
          <Modal.Body>individual has 5 days to add an item or else they will lose the bin.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModal}>
              Continue
            </Button>
          </Modal.Footer>
        </Modal>

      </>
  );
}

export default withRouter(AddClientPage);
