import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo';

// css imports
import 'react-html5-camera-photo/build/css/index.css';
import "./index.css"

export const CameraModal = props => {
  const {isOpen = true, handleChange } = props;
  const fileInput = React.createRef();

  const capture = (dataUrl) => {
    handleChange({ target: { name: "hasNewPhoto", value: true } });
    handleChange({ target: { name: "showWebcam", value: false } });
    handleChange({ target: { name: "imageSrc", value: dataUrl } });
  }

  const handleClose = () => {
    handleChange({ target: { name: "showWebcam", value: false } });
  }

  const handleChangeFile = (event) => {
    event.preventDefault();
    const files = fileInput.current.files;

    if (FileReader && files && files.length) {
      handleChange({ target: { name: "hasNewPhoto", value: true } });
      var FR = new FileReader();

      FR.onload = function () {
        handleChange({ target: { name: "imageSrc", value: FR.result } });
        handleChange({ target: { name: "showWebcam", value: false } });
      }
      FR.readAsDataURL(files[0]);
    }
  }

  return (
    <>
        <Modal
          show={isOpen}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="camera-modal-container"
        >
          <Modal.Header closeButton/>
          <Modal.Body  >
            <Camera
              onTakePhotoAnimationDone={capture}
              imageType={IMAGE_TYPES.JPG}
              idealResolution = {{width: 300, height: 300}}
            />

          <Button variant="dark" className="w-100 position-relative">
            Upload <input className="file-input position-absolute" id="file" name="file" type="file" onChange={handleChangeFile} ref={fileInput} />
          </Button>
          </Modal.Body>
        </Modal>
      </>
  )
}

export default CameraModal;