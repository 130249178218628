import React from "react";

function Details(props) {
  const { classes = {} } = props;
  let showHeader = props.header;
  return (
    <div className="info">
      {showHeader && <h3>{props.header}</h3>}
      <div className={`info_data row m-0 w-100 ${classes?.dataInfo}`}>
        {props.fields && props.fields.map((tuple, index) => (
          <div key={`${index}_${tuple.name}`} className={`data col-sm-12 col-md-6 p-0 ${classes?.data}`}>
            <h4>{tuple.name}</h4>
            <p> {tuple.value === '-' ? 'Not Listed': tuple.value} </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Details;
