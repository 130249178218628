import React, { useReducer } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from "react-bootstrap/Spinner";
import { useFormInput } from '../../hooks/useFormInput'
import { Endpoint } from "../../api/base";
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2";

const initialState = {errors: null, loading: false}
function reducer (state, action)  {
  switch(action.type) {
    case 'setErrors':
      return {...state, errors: action.payload}
    case 'setLoading':
      return {...state, loading: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

export const  WaitListModal = ({
                                 isOpen = false,
                                 handleClose = () => console.error("add close handler"),
                                 id = null,
                                 data = {},
                                 getData = () => {}
                               }) => {

  const email = useFormInput(id ?  data.email : '')
  const firstName = useFormInput(id ?  data.firstName : '')
  const lastName = useFormInput(id ?  data.lastName : '')
  const contactNumber = useFormInput(id ?  data.contactNumber : '')
  const notes = useFormInput(id ?  data.notes : '')
  const [state, dispatch] = useReducer(reducer, initialState, undefined)
  const history = useHistory()

  const clearForm = () => {
    firstName.onChange({target: {value: ""}})
    lastName.onChange({target: {value: ""}})
    email.onChange({target: {value: ""}})
    contactNumber.onChange({target: {value: ""}})
    notes.onChange({target: {value: ""}})
  }


  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate phone number
    // Valid phone numbers
    // (123) 456-7890
    // (123)456-7890
    // 123-456-7890
    // 123.456.7890
    // 1234567890
    // +31636363634
    // 075-63546725
    // eslint-disable-next-line no-useless-escape
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    let match = contactNumber.value.match(regex)
    if(contactNumber.value && !match){
      // Phone has failed the verification
      dispatch({ type: 'setErrors', payload: "Please enter a valid phone number" })
      return ;
    }

    const data = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      contactNumber: contactNumber.value,
      notes: notes.value
    }

    dispatch({ type: 'setLoading', payload: true })
    if(id) {
      Endpoint.WaitList.update({ ...data, id }).then(() => {
        dispatch({ type: 'setLoading', payload: false })
        handleClose()
        history.push('/app')
        history.push('/app/wait-list')
        getData && getData()
      }).catch(err => {
        dispatch({ type: 'setLoading', payload: false })
        //Failed to catch user profile
        console.error(err)
        Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
      })
    } else {
      Endpoint.WaitList.new(data).then(() => {
        dispatch({ type: 'setLoading', payload: false })
        handleClose()
        history.push('/app')
        history.push('/app/wait-list')
        clearForm()
        getData && getData()
      }).catch(err => {
        dispatch({ type: 'setLoading', payload: false })
        Swal.fire('Oops...', 'Failed to catch user profile', 'error')
        console.error(err)
      })
    }
  }


  return (
      <>
        <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="fix-modal"
        >
          <Modal.Header closeButton/>
          <Modal.Body>
            { state.loading && (
                <div className="w-100 form-loading-container">
                  <Spinner animation="border" variant="primary" />
                </div>
            ) }
            <form onSubmit={handleSubmit}>
              {state.errors && <p className="text-danger">{state.errors}</p>}
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control required { ...firstName } type="text" placeholder="Enter first name" />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control required { ...lastName } type="text" placeholder="Enter last name" />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control { ...email } type="email" placeholder="Enter email address" />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control { ...contactNumber } type="tele" placeholder="Enter a phone number" />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control { ...notes } type="text" as="textarea" rows={3} placeholder="Write Notes" />
                  </Form.Group>
                </Col>

                <Col xs={12}>
                  <Button type="submit" variant="dark" className="w-100 position-relative">
                    Add To Wait List
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </>
  )
}