import React, {useEffect, useState} from "react";
import {Table} from 'react-bootstrap'
import Details from "./Details";
import {formatToLocal, formatToLocalWoT} from '../../helpers/dateHelpers'
import { useOptionsState } from '../../helpers/optionsContext'
import {Endpoint} from "../../api/base";
import NotesList from "../NotesList";

const EmergencyContactsList = (props) => {
  const { classes = {} } = props;
  let showHeader = props.header;
  return (
    <div className="info">
      {showHeader && <h3>{props.header}</h3>}
      <div className={`info_data row m-0 w-100 ${classes?.dataInfo}`}>
		{ props.list && props.list.length ?

			<Table>
				<thead>
					<tr className="text-center">
						<th className="border-top-0">First Name</th>
						<th className="border-top-0">Last Name</th>
						<th className="border-top-0">Phone Number</th>
						<th className="border-top-0">Email Address</th>
					</tr>
				</thead>
				<tbody>
				{props.fields.map((contact, index) => (
					<tr className="text-center" key={contact.id || index}>
						<td>{contact.firstName === '' ? 'Not Listed' : contact.firstName}</td>
						<td>{contact.lastName === '' ? 'Not Listed' : contact.lastName}</td>
						<td>{contact.phoneNumber === '' ? 'Not Listed' : contact.phoneNumber}</td>
						<td>{contact.emailAddress === '' ? 'Not Listed' : contact.emailAddress}</td>
					</tr>
				))}
				</tbody>
			</Table>

		: <p>No Emergency Contact</p> }
      </div>
    </div>
  );

}

export const ClientDetails = ({ data = {} }) => {
  const { Race, ReferralOutCode, ReferralInfo, Ethnicity} = useOptionsState()
    const [notes, setNotes] = useState(undefined);

    useEffect(() => {
        Endpoint.Clients.Notes.get(data?.id).then(res => {
            setNotes(res.data?.filter(item => (item.noteTypeId === 1)))
        }).catch(e => {

        })
    }, [data?.id]);

  return (
    <React.Fragment>
 			<Details
        header="Personal Information"
        fields={[
          {
            name: "DOB",
            value: formatToLocalWoT(data.dateOfBirth),
          },
          {
            name: "Gender",
            value: data.gender,
          },
        ]}
      />

        <Details
            fields={[
                {
                    name: "Race",
                    value: data.raceId ? Race[data?.raceId]?.replace(/_/g, " "): "-",
                },
                {
                    name: "Ethnicity",
                    value: data?.ethnicityId ? Ethnicity[data?.ethnicityId]?.replace(/_/g, " "): "-",
                },
            ]}
        />

        <Details
            fields={[
                {
                    name: "Phone Number",
                    value: data?.phoneNumber ? data?.phoneNumber: "-",
                },
                {
                    name: "Email Address",
                    value: data?.emailAddress ? data?.emailAddress: "-",
                },
            ]}
        />

      <Details
        header="Other Information:"
        fields={[
          {
            name: "Veteran",
            value: data.veteran ? "Yes" : "No",
          },
          {
            name: "Intake Date",
            value: formatToLocal(data?.intake?.slice(-1)?.[0]?.dateTime+"Z"),
          },
        ]}
      />

        <Details
            fields={[
                {
                    name: "Expiration Date",
                    value: formatToLocal(data?.expirationDate),
                },
                {
                    name: "Term of Service Agreement Length",
                    value: data?.intake?.slice(-1)?.[0]?.termsOfServiceLength.termLengthDays,
                },
            ]}
        />
        <Details
            fields={[
                {
                    name: "MPA",
                    value: data.intake?.slice(-1)?.[0]?.mpa ? "Yes" : "No",
                },
                //FIND LATEST INTAKE AND SHOW THAT not [0] and use that latest intakeId in the issue mentioned in AddClientPage.jsx on line 131
                { 
                    name: "Referral",
                    value: data?.intake?.slice(-1)?.[0]?.intakeReferral ? data?.intake?.slice(-1)?.[0]?.intakeReferral?.map((item, index) =>
                        <span key={index} className="p-1 m-1 bg-info text-white rounded d-inline-block">{(item.referralInfoId && ReferralInfo[item.referralInfoId]) ? ReferralInfo[item.referralInfoId].replace(/_/g, " ") : "N/A"}</span>
                    ): "-",
                },
            ]}
        />
        <Details
            fields={[
                {
                    name: "Referral Out Code",
                    value: data?.intake?.slice(-1)?.[0]?.intakeReferralOut ? data?.intake?.slice(-1)?.[0]?.intakeReferralOut?.map((item, index) => ReferralOutCode? <span key={index} className="p-1 m-1 bg-info text-white rounded d-inline-block">{(item.referralOutCodeId && ReferralOutCode[item.referralOutCodeId]) ? ReferralOutCode[item.referralOutCodeId]?.replace(/_/g, " ") : "N/A"}</span> : ""): "-",
                },
            ]}
        />

        <EmergencyContactsList
            header="Emergency Contacts"
            classes={{ data: "w-100 text-justify" }}
            list={ data.emergencyContacts }
            fields={ data.emergencyContacts }
        />

      {/*  {notes && <Details*/}
      {/*  header="Notes"*/}
      {/*  classes={{data: "w-100 text-justify"}}*/}
      {/*  fields={ notes }*/}
      {/*/>}*/}

        {notes?.length ? <div className="info">
            <h3>Notes</h3>
            <NotesList noteTypeId={1} clientId={data?.id} />
        </div> : <></>}


   </React.Fragment>
  )
}

export default ClientDetails;
