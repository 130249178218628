import React from 'react'

export default function BinIcon(props){
    const iconStyle = {
        fill:'none',
        stroke: props.isSelected ? 'red' : '#000000',
        strokeMiterlimit:'10'
    }

    return(
        <svg className="svg-inline--fa fa-w-20 fa-lg"
             x="0px" y="0px"
             viewBox="0 0 24 24">
            <g>
                <path style={iconStyle} d="M20.6,8.1v10.4c0,0.7-0.6,1.4-1.4,1.4H4.7c-0.7,0-1.4-0.6-1.4-1.4V8.1"/>
                <path style={iconStyle} d="M16.6,5.1h4.7c0.4,0,0.8,0.3,0.8,0.8v1.5c0,0.4-0.3,0.8-0.8,0.8h-7.7h-2.8H8.6H6.9H2.8C2.3,8.1,2,7.7,2,7.3V5.8c0-0.4,0.3-0.8,0.8-0.8h11.4"/>
                <path style={iconStyle} d="M8.8,13v-1.8c0,0,0,0,0,0h6.3c0,0,0,0,0,0V13c0,0,0,0,0,0L8.8,13C8.8,13,8.8,13,8.8,13z"/>
                <circle cx="13.8" cy="5.1" r="0.9"/>
                <circle cx="16.7" cy="5.1" r="0.9"/>
            </g>
        </svg>
    )
}