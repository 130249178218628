import {Form} from "react-bootstrap";
import React from "react";

export const OptionFilter = props => {
    const { item = {}, handleChange = () => {} } = props
    return (
        <Form.Control ref={item.ref} onChange={handleChange(item)} size="sm" as="select">
            <option value="">All</option>
            {item?.options?.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </Form.Control>
    )
}