import {COMPARATOR_NAME_FOR_DATE, DATA_TYPES} from "./";
import React from "react";
import {RemoveFilterButton} from "./RemoveFilterButton";

export const Chip = props => {
    const { item, removeFilters } = props

    const handleDateFormat = (value) => {
        const formatedDate = new Date(value || new Date()).toLocaleDateString()
        const isToday = new Date(formatedDate).toLocaleDateString() === new Date().toLocaleDateString()
        return isToday ? "Today" : formatedDate;
    }

    switch(item.type){
        case DATA_TYPES.DATE: {
            const [value = new Date() , operator] = item.value
            return (
                <div className="table-filter-chip">
                    <span>{item.label} {COMPARATOR_NAME_FOR_DATE[operator]}  </span>
                    <span>{handleDateFormat(value)}</span>
                    <RemoveFilterButton removeFilters={removeFilters} name={item.name}/>
                </div>
            )
        }
        default: {
            return (
                <div className="table-filter-chip">
                    <span>{item.label}: </span>
                    <span>{item.value}</span>
                    <RemoveFilterButton removeFilters={removeFilters} name={item.name}/>
                </div>
            )
        }

    }
}