import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

export default function NotFoundPage() {
  return (
    <Row 
    	style={{height: "calc(100% - 80px)"}}
    	className="m-0 flex-column justify-content-center align-items-center"
    >
      <img width="400px" height="400px" src="/images/404.svg" alt="404"/>
        <Link to="/">Back to Home</Link>
    </Row>
  );
}
