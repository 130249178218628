import React, { useEffect, useReducer } from "react";
import { FieldArray, Field, Formik, Form } from "formik";
import {Button, Row, Alert, Col, Spinner} from 'react-bootstrap';
import { useOptionsState } from '../../helpers/optionsContext'
import { useParams, useHistory } from "react-router-dom"
import { Endpoint } from "../../api/base";
import Swal from "sweetalert2";
import {MuiThemeProvider} from "@material-ui/core";
import {customPickerTheme} from "../customPickerTheme";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// css
import "./index.css"
import Select from "react-select";

const initialState = {user: null, loading: true}
function reducer (state, action)  {
    switch(action.type) {
        case 'setUser':
            return {...state, user: action.payload}
        case 'setLoading':
            return {...state, loading: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export const CheckInForm = props => {
    const {xs = 12, sm = 12, md = 12, lg = 12, xl = 12} = props;
    const history = useHistory()

    const { ReferralOutCode, CheckInType } = useOptionsState()
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    const params = useParams()

    useEffect(() => {
        Endpoint.Clients.get(params.id).then(response => {
            dispatch({ type: 'setUser', payload: response.data })
            dispatch({ type: 'setLoading', payload: false })
        }).catch(err => {
            console.error(err)
            dispatch({ type: 'setLoading', payload: false })
            Swal.fire('Oops...', 'Failed to fetch user profile', 'error')
        })
    }, [params.id]);

    const handleSubmit = async (values) => {
        const clientId = props.clientId ? props.clientId : state.user.id
        console.log(values)
        dispatch({ type: 'setLoading', payload: true })
        try {
            const checkinPayload = {
                "ClientId": clientId,
                "CheckInTime": values.CheckInTime,
                "CheckInTypeId": parseInt(values.checkinType),
                "checkInReferralOut": values?.referralOutCodeId?.map(item => ({ referralOutCodeId: item }))
            }
            // eslint-disable-next-line
            const inventoryResp = await Endpoint.Clients.checkin(checkinPayload)
            history.push(`/app/clients/${clientId}`);
        } catch (err) {
            dispatch({ type: 'setLoading', payload: false })
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
        }

    }

    return (
        state.user ? (
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="p-3">
                    {history.action !== 'POP' && <Button onClick={() => history.goBack()} className="back-btn" variant="dark">Back</Button>}
                    <Formik
                        initialValues={{
                            isEmpty: false,
                            checkinType: Object.keys(CheckInType)[1],
                            CheckInTime: new Date().toISOString(),
                        }}
                        onSubmit={handleSubmit}
                    >
                        {
                            ({ values, handleChange, setFieldValue }) => (
                                <Form>
                                    <FieldArray name="inventory">
                                        {(inventoryActions) => (
                                            values.isEmpty ?
                                                <Alert variant="warning" className="w-100 mx-3 my-0" dismissible>
                                                    <p className="my-0">Are you sure you want to <span className="font-weight-bold">Empty</span> the bin!</p>
                                                </Alert> : (

                                                    <Row className="m-0">
                                                        <Col xs={12} sm={12}>
                                                            <h3 className="pb-3">{`${state.user?.firstName} ${state.user?.lastName}'s Bin`}</h3>
                                                        </Col>

                                                        <Col xs={6} sm={6}>
                                                            <div className="field_element">
                                                                <label htmlFor="exampleFormControlSelect1">Checkin Type</label>
                                                                <Field
                                                                    className="form-control form-control-sm"
                                                                    as="select"
                                                                    size="sm"
                                                                    name={`checkinType`}
                                                                >
                                                                    {Object.keys(CheckInType).map( typeKey =>
                                                                        <option key={typeKey} value={typeKey} label={CheckInType[typeKey]} />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={6}>
                                                            <div className="field_element">
                                                                <label htmlFor="CheckInTime">Checkin Date</label>
                                                                <MuiThemeProvider theme={customPickerTheme}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} customTheme>
                                                                        <DateTimePicker
                                                                            name="CheckInTime"
                                                                            InputProps={{ className:"form-control form-control-sm", disableUnderline: true }}
                                                                            value={values.CheckInTime}
                                                                            format="dd-MM-yyyy hh:mm"
                                                                            onChange={value => setFieldValue("CheckInTime", value)}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </MuiThemeProvider>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12}>
                                                            <div className="form-group px-0 pb-2 check-in-type-form">
                                                                <label htmlFor="ReferralOutCodeId">Referral Out Code</label>
                                                                <Select
                                                                    className="referral-select-component"
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    onChange={(data) => handleChange({target: {
                                                                            name: "referralOutCodeId",
                                                                            value: data?.map(item => item.value)
                                                                        }})}
                                                                    defaultValue={values?.referralOutCodeId?.map(value => ({
                                                                        value,
                                                                        label: ReferralOutCode ? ReferralOutCode[value]?.replace(/_/g, " ") : ""
                                                                    }))}
                                                                    options={Object.keys(ReferralOutCode||{}).map(key => ({
                                                                        label:  ReferralOutCode[key]?.replace(/_/g, " "),
                                                                        value: parseInt(key)
                                                                    }))}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                        )}
                                    </FieldArray>
                                    <Col xs={12}  className="px-0">
                                        <Row style={{ marginTop: '15px' }} className="justify-content-center">
                                            <Button className="px-5 check-in-form-submit-button" variant="success" type="submit"  disabled={state.loading}>
                                                {state.loading ? <Spinner size="sm" animation="border" variant="primary" />: "Submit"}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Form>
                            )}
                    </Formik>
                </Col>
            )
            :
            <Row className="checkin-form-loading-container m-0 justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
            </Row>
    )
}


export default CheckInForm;