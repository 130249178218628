
import React, { useReducer } from "react";
import { Modal } from 'react-bootstrap';
import { Endpoint } from "../api/base";
import Camera from "react-html5-camera-photo";
import Loading from './Loading';
import { useHistory } from "react-router-dom";
import "react-html5-camera-photo/build/css/index.css";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

const initialState = {show: false, loading: false}
function reducer (state, action)  {
    switch(action.type) {
        case 'setShow':
            return {...state, show: action.payload}
        case 'setLoading':
            return {...state, loading: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export default function FaceDetection(props) {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    const handleClose = () => dispatch({ type: 'setShow', payload: false })
    const handleShow = () => dispatch({ type: 'setShow', payload: true })
    const history = useHistory();

    function dataURItoBlob(dataURI) {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    function handleTakePhoto(dataUri) {
        const formData = new FormData();
        formData.append("img", dataURItoBlob(dataUri));
        const promise = Endpoint.Clients.IdentifyClient(formData);
        dispatch({ type: 'setLoading', payload: true })
        promise
            .then((response) => {
                dispatch({ type: 'setLoading', payload: false })
                if (response) {
                    dispatch({ type: 'setShow', payload: false })
                    history.push("/app/clients/" + response.data.id);
                }else{
                    Swal.fire('Oops...', 'Person not found', 'error')
                }
            })
            .catch((err) => {
                dispatch({ type: 'setLoading', payload: false })
                dispatch({ type: 'setShow', payload: false })
                console.log(err)
                Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
            });
    }

    return (
        <>
            <Button size="sm" variant="dark" onClick={handleShow}>Face Detection</Button>

            <Modal show={state.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Face Detection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.loading
                    ? <Loading height="300px" /> 
                    : <Camera
                        onTakePhoto={(dataUri) => {
                            handleTakePhoto(dataUri);
                        }}
                    />
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}
