import React, {useReducer} from "react";
import { Col, Row } from "react-bootstrap";
import ClientInfoPopUp from "../components/ClientInfoPopUp";
import Loading from '../components/Loading'


const initialState = {loading: false, clientId: undefined, clientInfoPopUpShow: false}
function reducer (state, action)  {
    switch(action.type) {
        case 'setLoading':
            return {...state, loading: action.payload}
        case 'setClientId':
            return {...state, clientId: action.payload}
        case 'setClientInfoPopUpShow':
            return {...state, clientInfoPopUpShow: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

function HomePage() {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)

    return (
        <Row className="m-0 flex-nowrap w-100">
            { !state.loading && true &&
            <Col
                xs={12}
                style={{minHeight: "calc(100vh - 70px)", flex: "1"}}
                className="m-0 pl-2 pr-2 pl-lg-0 pr-lg-3 py-4 embed-responsive"
            >
                <iframe
                    src={process.env.REACT_APP_POWERBI_URL}
                    frameBorder="0"
                    title="PowerBI Report"
                />
            </Col>
            }

            { state.loading && <Loading height="calc(100vh - 80px)" />}

            { !state.loading && state.clientId &&
            <ClientInfoPopUp
                status={state.clientInfoPopUpShow}
                onClose={() => dispatch({ type: 'setClientInfoPopUpShow', payload: true })}
                id={state.clientId}
            />
            }
        </Row>
    );
}

export default HomePage;
