import React from "react";

export const RemoveFilterButton = props => {
    const { removeFilters, name } = props;
    return (
        <button
            style={{marginLeft: "5px", lineHeight: 0.8}}
            onClick={() => removeFilters(name)}
            type="button"
            className="close"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    )
}