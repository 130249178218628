import {DateFilter} from "./DateFilter";
import React from "react";
import {DATA_TYPES, OptionFilter} from "./";

export const ColumnFilter = props => {
    const { item = {}, handleChange = () => {} } = props

    const defaultFilter = <input ref={item.ref} onChange={handleChange(item)}  placeholder={`${item?.label || ""} Search...`} className="form-control form-control-sm" type="text"/>

    if(item.filterComponent){
        const FilterComponent = item.filterComponent;
        return <FilterComponent { ...props }/>
    }
    switch (item.type){
        case DATA_TYPES.DATE:
            return <DateFilter  {...props }/>
        case DATA_TYPES.OPTION:
            return <OptionFilter  {...props }/>
        default:
            return defaultFilter
    }
}