import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'


export const ConfirmModal = ({
  isOpen = false,
  text = "are you sure?",
  submitText = "Delete",
  submitVariant = "danger",
  handleClose = () => console.error("add close handler!"),
  onConfirm = () => console.error('add confirm handler'),
  loading = false
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton/>
      <Modal.Body>
      { loading && (
              <div className="w-100 form-loading-container">
                <Spinner animation="border" variant="primary" />
              </div>
            ) } 
        <h6>{text}</h6>
        <Row className="mt-5">
          <Col xs={6}>
            <Button variant="secondary" size="sm" className="w-100" onClick={handleClose}>Cancel</Button>
          </Col>
          <Col xs={6}>
            <Button variant={submitVariant} size="sm" className="w-100" onClick={onConfirm}>{submitText}</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}