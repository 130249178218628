import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { DateDisplay } from "../DateDisplay"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUsers, faClock, faListAlt } from '@fortawesome/free-solid-svg-icons'
import BinIcon from '../../images/BinIcon'
import { useHistory, Link } from "react-router-dom"

import "./index.css";

export function SideBar() {
  const history = useHistory()

  const isSelected = selectedRoute => history.location.pathname === selectedRoute;

  return (
    <Navbar className="main-side-bar flex-column p-0 px-lg-3 pb-lg-3 pt-lg-2">
      <Link className="mb-4 d-none d-lg-block" to="/app">
        <img className="logo" src="/images/storage_connect.png" alt="Storage Connect" />
      </Link>
      <Navbar.Collapse className="list-container flex-column flex-grow-1 w-100 justify-content-between" id="responsive-navbar-nav">
        <Nav className="flex-row flex-lg-column justify-content-between justify-content-lg-start w-100 mt-0 mt-lg-3 px-2 px-lg-0" >

          <NavLink className={`nav-link py-2 p-1 p-lg-3  ${isSelected('/app')? 'selected-route' : ''}`} to="/app">
            <FontAwesomeIcon size="lg" icon={faHome} /><span className="d-none d-lg-block">Home</span>
          </NavLink>

          <NavLink className={`nav-link py-2 p-1 p-lg-3 ${isSelected('/app/bins')? 'selected-route' : ''}`} to="/app/bins">
            <BinIcon isSelected={isSelected('/app/bins')}/><span className="d-none d-lg-block">Bins</span>

          </NavLink>

          <NavLink className={`nav-link py-2 p-1 p-lg-3 ${isSelected('/app/clients')? 'selected-route' : ''}`} to="/app/clients">
            <FontAwesomeIcon size="lg" icon={faUsers} /><span className="d-none d-lg-block">Clients</span>
          </NavLink>

          <NavLink className={`nav-link py-2 p-1 p-lg-3 ${isSelected('/app/add-client')? 'selected-route' : ''}`} to="/app/add-client">
            <FontAwesomeIcon size="lg" icon={faListAlt} /><span className="d-none d-lg-block">In Take</span>
          </NavLink>

          <NavLink className={`nav-link py-2 p-1 p-lg-3 ${isSelected('/app/wait-list')? 'selected-route' : ''}`} to="/app/wait-list">
            <FontAwesomeIcon size="lg" icon={faClock} /><span className="d-none d-lg-block">Wait List</span>
          </NavLink>

        </Nav>
        <Nav className="flex-column d-none d-lg-block w-100" ><DateDisplay/></Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default SideBar;
