import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import "./index.css";

export const DateDisplay = props => {
  const [weekDay, month, date, year] = new Date().toDateString().split(' ')

  return (
    <div className="row m-0 p-3 align-items-end">
      <FontAwesomeIcon size="2x" className="mr-2 " icon={faCalendarAlt} />
      <div>
        <span className="week-day">{weekDay}</span>
        <h6 className="full-date m-0"><span>{month}</span> <span>{date}</span>, <span>{year}</span></h6>
      </div>
    </div>
  )
}

export default DateDisplay;