import React, { useEffect, forwardRef, useImperativeHandle, useReducer } from 'react'
import {Container, Row, Col, Button} from 'react-bootstrap'
import {Field, Formik} from 'formik'
import {Endpoint} from '../../api/base'
import { formatToLocal } from '../../helpers/dateHelpers'

// css files
import 'font-awesome/css/font-awesome.min.css';
import './NotesList.css'
import Swal from "sweetalert2";

const initialState = {editNote: '', loading: false, notes: [], isEditNote: false}
function reducer (state, action)  {
    switch(action.type) {
        case 'setEditNote':
            return {...state, editNote: action.payload}
        case 'setLoading':
            return {...state, loading: action.payload}
        case 'setNotes':
            return {...state, notes: action.payload}
        case 'setIsEditNote':
            return {...state, isEditNote: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

const NotesList = forwardRef((props, ref) => {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    const { setNotes = () => {}, noteTypeId, clientId } = props

    useImperativeHandle(ref, () => ({
        async reloadNotes() {
            reloadNotes()
        }
    }));

    const reloadNotes = () => {
        Endpoint.Clients.Notes.get(clientId).then(res => {
            const notes = noteTypeId ? res.data?.filter(item => (item.noteTypeId === noteTypeId)) : res.data;
            dispatch({ type: 'setNotes', payload: notes });
            setNotes(notes);
        }).catch(e => {

        })
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await Endpoint.Clients.Notes.get(clientId)
            const notes = noteTypeId ? response.data?.filter(item => (item.noteTypeId === noteTypeId)) : response.data;
            dispatch({ type: 'setNotes', payload: notes });
        };
        fetchData();
    }, [clientId, noteTypeId]);

    const handleEdit = async note => {
        dispatch({ type: 'setLoading', payload: true })
        const payload =[
            {
                "NoteTypeId": note.noteTypeId,
                "Value": state.editNote,
                "ListAction":2,
                "LastModified":new Date().toISOString(),
                "id": note.id
            }
        ]
        try {
            const response = await Endpoint.Clients.Notes.new({id:clientId, data: payload})
            console.log(response.data)
            Swal.fire('Successfully', 'Message Modified Successfully', 'success')
            reloadNotes()
            dispatch({ type: 'setLoading', payload: false })
        } catch (err) {
            //Failed to Modify message, please try again
            dispatch({ type: 'setLoading', payload: false })
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
        }
    }

    const handleDelete = async note => {
        dispatch({ type: 'setLoading', payload: true })
        const payload = [
            {
                NoteTypeId: note.noteTypeId,
                Value: state.editNote,
                ListAction: 3,
                Id: note.id
            }
        ]
        try {
            const response = await Endpoint.Clients.Notes.new({id:clientId, data: payload})
            console.log(response)
            Swal.fire('Successfully', 'Message Deleted Successfully', 'success')
            reloadNotes()
            dispatch({ type: 'setLoading', payload: false })
        } catch (err) {
            dispatch({ type: 'setLoading', payload: false })
            if(err.response) {
                console.log(err.response.data)
            } else {
                console.log(err.message)
            }
        }

    }

    return(
        <Container className="notes-list" style={props.intake ? {marginLeft:0} : {}}>
            <Row>
                <div className="notes-title"><h4>{props.title}</h4></div>
                {state.notes.map( ( (note, index) => (
                    // eslint-disable-next-line no-unused-expressions
                    <Formik
                        key={note.id}
                        initialValues={{
                            Notes: state.editNote === '' ? note.value : state.editNote,
                            isEditNote: undefined
                        }}
                    >
                        { ({ values, setFieldValue }) => (
                            <Col xs={12} md={12}>
                                <section className="notes-section">
                                    <article className="notes-header">
                                        <div>
                                            <h4>{note.employee === null ? `Name` : note.employee}</h4>
                                            <p>{ formatToLocal(note.lastModified+"Z") }</p>
                                        </div>
                                        { note.clientId === clientId ? (
                                            <div className="action">
                                                {note.isNoteFromEmployee && <Button variant="outline-danger" onClick={() => handleDelete(note)} size="sm">Delete</Button>}

                                                {values.isEditNote && values.isEditNote[index] ?
                                                    <Button variant="outline-success"
                                                            size="sm"
                                                            onClick={ () => setFieldValue(`isEditNote.${index}`, false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    :
                                                    note.isNoteFromEmployee && <Button variant="outline-success"
                                                            size="sm"
                                                            onClick={ () => { setFieldValue(`isEditNote.${index}`, true)}}
                                                    >
                                                        Modify
                                                    </Button>
                                                }{' '}
                                            </div>
                                        ) : null }
                                    </article>
                                    {values.isEditNote && values.isEditNote[index] ?
                                        <>
                                            <Field
                                                component="textarea"
                                                name="Notes"
                                                value={values.Notes || ''}
                                                className="form-control add_client__notes"
                                                tabIndex="40"
                                                onBlur={ () => {
                                                    dispatch({ type: 'setEditNote', payload: values.Notes })
                                                }}
                                            />
                                            <Button variant="outline-success mt-3"
                                                    size="sm"
                                                    onClick={ () => {
                                                        setFieldValue(`isEditNote.${index}`, false)
                                                        dispatch({ type: 'setEditNote', payload: values.Notes })
                                                        handleEdit(note).then()}
                                                    }
                                                    disabled={state.loading}
                                            >
                                                Post
                                            </Button>
                                        </>
                                        :
                                        <h6 className="note-text">
                                            {values.Notes || ''}
                                        </h6>}
                                </section>
                            </Col>
                        )}
                    </Formik>
                ))) }
            </Row>
        </Container>
    )
})

export default NotesList